import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {AutoComp} from '../../components/textField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import './style.css';
import Meta from "../../components/meta";
import CurrencyFormat from "react-currency-format";
import  { Redirect } from 'react-router-dom'



const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: deepPurple,
      secondary: teal,
    
  },
 

}
  
)



export default class Projeto extends Component {


  state = {
    empresa_id: localStorage['empresa_id'],
    permission: localStorage['permission'],
    token: localStorage.getItem("tokens"),
    loading:true,
    loadPerc:0,
    red:''
  }
 


  // LOADING

  toLoad = () => {
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){
    
    //window.location.href = 'https://app.landscapeos.io'
    this.setState({ loading: true });
    this.setPage();
    this.loadProjetos();
    
  }



  // LISTA DE ORCAMENTOS


  loadProjetos = async () => {
    
    this.toLoad()

    api.get('/get/projetosAprovados.php?empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        
            this.setState({
              ativos:result.data.ativos,
             
        })
         
          this.endLoad()

      }

      
    })
    
      
  }

    
// ULTIMA PÁGINA VISITADA

setPage = async () => {
  
  localStorage.setItem("page",'/projetos');
  api.get('update/setPage.php?page=/projetos&token='+this.state.token);

}


// VIEW PROJECT

view = (w) => {

  window.open("view/"+w)
 

}


// VOID

void = () => {
  
}

// CHANGE PROJECT

changeProject = (e) => {
 
  this.setState({
    red: <Redirect to={"/view/"+e}></Redirect>
  })
}
  render(){


  return (

   
    <ThemeProvider theme={theme}>
       
       {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
<Meta title="Projetos"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0 }/>
        
      <div className="conteudo">
      <Grid container spacing={3}>
            
            <Grid item xs={8}><div className="titulo">Projetos</div></Grid>
             
              <Grid item xs={4}><AutoComp empresa_id={this.state.empresa_id} sheet="projeto" tipo="projeto_aprovado" label="Busque pelo projeto" blur={()=>this.void()} change={(e)=>this.changeProject(e)}/></Grid>
             
          </Grid>
            

          <div className="mt10 mb5">
                  <Grid container spacing={0} className={"bgcolor_1 pd30 mt20 br5"}>
                        <Grid item xs={6} className="mini-titulo"><div className="mt10">Projetos Ativos</div></Grid>
                  </Grid>
          </div>


          <div className="mt5 mb5">
                  <Grid container spacing={0} className={"bgcolor_1 br5"}>
                        <Grid item xs={5} className="micro-titulo"><div className="mt5 pd15">Projeto</div></Grid>
                        <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Valor total</div></Grid>
                        <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Custos projetados</div></Grid>
                        <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Custos realizados</div></Grid>
                        <Grid item xs={1} className="micro-titulo"><div className="mt5 pd15 tr">Status</div></Grid>
                  </Grid>

          </div>

          {this.state.ativos ? this.state.ativos.map((ativos,objectID)=> (
          <div key={objectID} onClick={(w)=>this.view(ativos.id)} className="mb1 line">
                  <Grid container spacing={0} className={"bgcolor_7 br5"}>
                      
                        <Grid item xs={5} className=""><div className="mt5 pd15"><span className="color_1">{ativos.id}</span> {ativos.cliente+' - '+ativos.nome}</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">{<CurrencyFormat value={ativos.venda}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">{<CurrencyFormat value={ativos.previsto}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15">{<CurrencyFormat value={ativos.realizado}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 tr">{ativos.previsto>0 ? Math.floor(ativos.realizado/ativos.previsto*100)+'%' : '0%'}</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 tr">{ativos.previsto/1>=ativos.realizado/1 ? <div className="bar-status bgcolor_2 mb5 r"></div> : <div className="bar-status bgcolor_5 mb5 r"></div>}</div></Grid>
                  
                  </Grid>

          </div>

          )) : ''}


    
          </div>
   
          {this.state.red}

    </ThemeProvider>
  )


}
}
  