import { Grid, ThemeProvider, createTheme, Tooltip, IconButton,Zoom, CircularProgress } from '@material-ui/core'
import TextField from '@mui/material/TextField';
import {deepPurple,teal} from '@material-ui/core/colors';
import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
import SearchIcon from '@material-ui/icons//Search';
import { MySelect } from '../../components/textField';
import api from '../../services/api';


const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    },overrides: {
        MuiTooltip: {
            tooltip: {
              fontSize: "11px",
              backgroundColor:'#666'
        }},
        MuiIconButton: {
          root: {
              color:'#777',
            '&:hover': {
              color: '#ddd'
            }
          }
        }
      }

   
    })

    

export default class Line extends Component {
  
    constructor(props){
        super();
        this.state={
            comentario:props.comentario,
            nome:props.nome,
            o_qt:props.o_qt,
            o_diaria:props.o_diaria,
            o_valor:props.o_valor,
            fornecedor:props.fornecedor,
            o_res:props.o_qt/1*props.o_diaria/1*props.o_valor/1,
            listaFornecedores:[],
            busca:<div><Tooltip title="Buscar fornecedor" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={()=>this.buscaFornecedor(props.plano_conta_id)}><SearchIcon fontSize="small"/></IconButton></Tooltip>{props.fornecedor}</div>
        }
    }

    handleChange = (w,e,index,f) =>{
        
        if(e.target.value !== ""){
            if(w === 0){
                let comentario = e.target.value
                this.setState({comentario:comentario.replace(/[^a-zA-Z0-9]/g, ' ')})
                this.props.upBlocoValor(w,e.target.value.replace(/[^a-zA-Z0-9]/g, ' '),index,this.props.sub_nucleos);
            }
            if(w === 1){this.setState({o_qt:e.target.value})
                this.props.upBlocoValor(w,e.target.value,index,this.props.sub_nucleos);
            }
            if(w === 2){this.setState({o_diaria:e.target.value})
                this.props.upBlocoValor(w,e.target.value,index,this.props.sub_nucleos);
            }
            if(w === 3){this.setState({o_valor:e.target.value})
                this.props.upBlocoValor(w,e.target.value,index,this.props.sub_nucleos);
            }
            if(w === 4){this.setState({nome:e.target.value.replace(/[^a-zA-Z0-9]/g, ' ')})
                this.props.upBlocoValor(w,e.target.value.replace(/[^a-zA-Z0-9]/g, ' '),index,this.props.sub_nucleos);
            }
            
        }

        
        

    setTimeout(() => {
        this.updadeValor(w)
        }, 0);
        if(f !== 1){
        this.props.focus(0)
        }
    }


    buscaFornecedor = (w) => {

        this.setState({
            busca:<CircularProgress size={20} color="secondary"/>
            
        })

        
        api.get('get/getSelect.php?id='+w+'&sheet=users&col_id=live&r='+Math.random(10000)).then(result => {
        
            if (result.status === 200) {
                this.setState({
                    nextSearch:w,
                    listaFornecedores:result.data,
                    busca: <MySelect change={(e) => this.changeFornecedor(e)} label="Selecione" list={result.data} ></MySelect>
                })

            }})

       
       

    }



    changeFornecedor = (e) => {
        
        let user = this.state.listaFornecedores.filter(line => line.id === e);
        
        let valor = user[0]['valor'];
        let fornecedor = user[0]['label'];
        this.setState({
            o_valor:valor,
            fornecedor:fornecedor,
            busca:'',
            listaFornecedores:[]
        })

     
        this.props.changeFornecedor(this.props.keyid,e,fornecedor,valor,this.props.sub_nucleos)
        
        setTimeout(() => {
            this.setState({
                busca: <div><Tooltip title="Buscar fornecedor" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={()=>this.buscaFornecedor(this.props.plano_conta_id)}><SearchIcon fontSize="small"/></IconButton></Tooltip>{this.state.fornecedor}</div>,
                
            })
            this.updadeValor()
        }, 0);

        
    }



    updadeValor = (w) => {
        this.setState({
            o_res:this.state.o_qt/1*this.state.o_diaria/1*this.state.o_valor/1,
        })
        
    }

    
    handleKeyDown = (w,e,index,nav,plano_conta_id) => {
        
        nav = nav.split('_');

        if(e.key==='Tab'){
            e.preventDefault();
            if(nav[0]<3){
                this.props.focus(nav[0]/1+1+'_'+(nav[1]));
            
            }else{
                this.props.focus('0_'+(nav[1]/1+1));
            }

            this.handleChange(w,e,index,1)
        }else{


        
       
        if(e.keyCode === 40){
           this.props.focus(nav[0]+'_'+(nav[1]/1+1));
           
                this.handleChange(w,e,index,1)
            
        }

        if(e.keyCode === 38){
            
            this.props.focus(nav[0]+'_'+(nav[1]/1-1));
          
                this.handleChange(w,e,index,1)
            
        }

        if(e.keyCode === 39){
            if(nav[0]<3){
                this.props.focus((nav[0]/1+1)+'_'+(nav[1]));
                this.handleChange(w,e,index,1)
            }
            
        }

        if(e.keyCode === 37){
            if(nav[0]>0){
                this.props.focus((nav[0]/1-1)+'_'+(nav[1]));
                this.handleChange(w,e,index,1)
            }
            
        }


        if(e.keyCode === 191){
            e.preventDefault();
            this.helperBudget(plano_conta_id,index);
            
        }

        }
        
    }

    // HELPER

    helperBudget = (w,index) => {

        api.get('get/magicCost.php?id='+w+'&r='+Math.random(10000)).then(result => {
        
            if (result.status === 200) {
              this.setState({
                  magicCost:result.data,
                  magicIndex:index
              })

            }})

    }


    // SET MAGIC VALOR

    setMagic = (valor) => {

        this.setState({
            o_valor:valor,
            magicCost:false
        })

        setTimeout(() => {
            this.props.upBlocoValor(3,valor,this.state.magicIndex,this.props.sub_nucleos);
            this.updadeValor()
        }, 10);

        

    }

    
    render() {
        return (
            
            <ThemeProvider theme={theme}>
               
                    
                <Grid container spacing={0} className="bgcolor_7 br5 mb1 line-budget">
               
                    <Grid item xs={3}>{this.props.editLine === ('n_'+this.props.id) ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onKeyDown={(e)=>this.handleKeyDown(5,e,this.props.keyid,('n_'+this.props.id))} onBlur={(e)=>this.handleChange(4,e,this.props.keyid)} defaultValue={this.state.nome} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#555'}}}/> : <div onClick={(w)=>this.props.focus('n_'+this.props.id)} className='ff c2'>{this.state.nome}</div>}</Grid>
                    <Grid item xs={3}>{this.props.editLine === ('0_'+this.props.id) ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onKeyDown={(e)=>this.handleKeyDown(0,e,this.props.keyid,('0_'+this.props.id))} onBlur={(e)=>this.handleChange(0,e,this.props.keyid)} defaultValue={this.state.comentario} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}}}/> : <div onClick={(w)=>this.props.focus('0_'+this.props.id)} className='ff c1'>{this.state.comentario}</div>}</Grid>
                    <Grid item xs={2}><div className='search-line bgcolor_10'>{this.props.bloco==='Live Action' ? this.state.busca : ''}</div></Grid>
                    <Grid item xs={1}>{this.props.editLine === ('1_'+this.props.id) ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onKeyDown={(e)=>this.handleKeyDown(2,e,this.props.keyid,('1_'+this.props.id))} onBlur={(e)=>this.handleChange(2,e,this.props.keyid)} InputProps={{style: { height:0,padding: '18px 0px'}, }}/>: <div onClick={(w)=>this.props.focus('1_'+this.props.id)} className='ff'>{this.state.o_diaria}</div>}</Grid>
                    <Grid item xs={1}>{this.props.editLine === ('2_'+this.props.id) ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onKeyDown={(e)=>this.handleKeyDown(1,e,this.props.keyid,('2_'+this.props.id))} onBlur={(e)=>this.handleChange(1,e,this.props.keyid)} InputProps={{style: { height:0,padding: '18px 0px',backgroundColor:'#444'}, }}/> : <div onClick={(w)=>this.props.focus('2_'+this.props.id)} className='ff c1'>{this.state.o_qt}</div>}</Grid>
                    <Grid item xs={1}>{this.props.editLine === ('3_'+this.props.id) ? <TextField autoFocus={true} variant="outlined" fullWidth={true} onKeyDown={(e)=>this.handleKeyDown(3,e,this.props.keyid,('3_'+this.props.id),this.props.plano_conta_id)} onBlur={(e)=>this.handleChange(3,e,this.props.keyid)} InputProps={{style: { height:0,padding: '18px 5px'}, }} currencysymbol="R$" outputformat="number"/> : <div onClick={(w)=>this.props.focus('3_'+this.props.id)} className={'ff'}><CurrencyFormat value={this.state.o_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></div>} </Grid>
                    <Grid item xs={1}><div className="ff-ro b bgcolor_10"><CurrencyFormat className="" value={this.state.o_res} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></div></Grid>
                </Grid>
                

                
               
                {this.state.magicCost ? 
                    this.state.magicCost.map((magic,objectId)=>(
                        <Grid container spacing={0} className="bgcolor_7 br5 mb1 line-budget">
                        <Grid item xs={10}><div className='ff-ro tr'>Orçado {magic.vezes} vezes.</div></Grid>
                        <Grid item xs={1}>
                            <div className='bt b nano-titulo  bgcolor_2' onClick={()=>this.setMagic(magic.o_valor)}><CurrencyFormat className="" value={magic.o_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} /></div>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        </Grid>
                    ))
                
            :''}

               



            </ThemeProvider>
        )
    }
}


                        