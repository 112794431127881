import React, { Component} from "react";
import Grid from '@material-ui/core/Grid';
import {MyDatePicker,AutoComp, MySelect, MySwitch} from '../../components/textField';
import Switch from '@material-ui/core/Switch';
import TextField from '@mui/material/TextField';
import api from '../../services/api';
import {createTheme,ThemeProvider } from '@material-ui/core/styles';
import {deepPurple, teal} from '@material-ui/core/colors';
import ProgressBar from '../../components/progress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import './style.css';
import Meta from "../../components/meta";
import CurrencyFormat from "react-currency-format";
import { DialogActions, DialogTitle, IconButton, ListItemText, Menu, MenuItem, Tooltip, Zoom } from "@material-ui/core";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Add, AddToPhotos, CalendarToday, Check, Comment, Delete, Dvr, FileCopy, Group, InsertDriveFile, ListAlt, Lock, LockOpen, Payment, Receipt, Visibility, VisibilityOff,AssignmentInd, Videocam, MoreVert, StarBorder, Search, Description, Tune, Gif } from "@material-ui/icons";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { Redirect } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InputMask from 'react-input-mask';
import Sticky from 'react-sticky-el';
import ListaProjetos from '../cashflow/listaProjeto';
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import { HourglassTop } from "@mui/icons-material";
import TrackRecord from './TrackRecord'

const theme = createTheme (
  {
    palette: {
      type: "dark",
      primary: {main: '#e65229'},
      secondary: teal,
    
  },
  overrides: {
      MuiTooltip: {
          tooltip: {
            fontSize: "11px",
            backgroundColor:'#666'
      }},
      MuiIconButton: {
        root: {
            color:'white',
          '&:hover': {
            color: '#4aedc4',
          }
        }
      }
    }

}
  
)

const StyledMenuItem = withStyles((theme) => ({
  root: {
      '&:focus': {
      backgroundColor: deepPurple,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: 'white',
      },
      },
  },
  }))(MenuItem);


export default class View extends Component {


  state = {
    token: localStorage.getItem("tokens"),
    empresa_id: localStorage['empresa_id'],
    usuario_id: localStorage['usuario_id'],
    permission: localStorage['permission'],
    id:this.props.match.params.id,
    loading:true,
    loadPerc:0,
    sub_nucleos:['Direcao','Producao','Seguranca e testes','Fotografia','Equipamento de Camera','Luz e Infra','Arte','Locacao','Figurino e Make','Producao de Elenco','Elenco','Transporte','Alimentacao','Acompanhamento'],
    sub_nucleos_display: ['Direção','Produçao','Segurança e testes','Fotografia','Equipamento de Câmera','Luz e Infra','Arte','Locação','Figurino e Make','Produção de Elenco','Elenco','Transporte','Alimentação','Acompanhamento'],
    blocos: ['Live Action','Time Interno','Fornecedores','Copias','Ancine'],
    fields:['r_qt','r_diaria','r_valor','comentario','p_qt','p_diaria','p_valor','nome','o_qt','o_diaria','o_valor'],
    chaves:[{'value':'E-mail','label':'E-mail'},{'value':'CPF','label':'CPF'},{'value':'Celular','label':'Celular'}],
    listaVerbas:[{'value':150,'label':'Verba de produção'},{'value':242,'label':'Verba de locação'},{'value':231,'label':'Verba de objetos'},{'value':229,'label':'Verba de cenografia'},{'value':230,'label':'Verba de efeitos'},{'value':235,'label':'Verba de culinarista'},{'value':256,'label':'Verba de figurino'},{'value':258,'label':'Verba de prova de figurino'},{'value':262,'label':'Verba de cabelo/insumos'},{'value':312,'label':'Verba de alimentação'},{'value':196,'label':'HD Externo'},{'value':329,'label':'Per Diem'},{'value':269,'label':'Cachê Teste'}],
    ativos:0,
    blocosAtivos:[1,1,1,1,1,1],
    lastAtivos:[1,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sub_nucleos_valor: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sub_nucleos_previsto: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sub_nucleos_real: [0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    valor_live_total:0,
    valor_live_previsto:0,
    valor_live_real:0,
    valor_time_interno:0,
    valor_time_interno_real:0,
    valor_fornecedores_post:0,
    valor_fornecedores_post_real:0,
    valor_copias:0,
    valor_copias_real:0,
    valor_ancine:0,
    valor_ancine_real:0,
    valor_api:0,
    valor_api_real:0,
    valor_comissao:0,
    valor_comissao_real:0,
    sendOpen:false,
    dataOpen:false,
    cnpjOpen:false,
    cnpjOpen2:false,
    verbaOpen:false,
    acessoOpen:false,
    dataAberta:0,
    qualData:0,
    edit:0,
    view:0,
    viewB:[],
    infoPo:[],
    abreInfoPo:false,
    comments:0,
    update:0,
    fornecedor:[],
    fornecedorNovo:0,
    lineId:0,
    closed:0,
    closeBudget:null,
    budgetClosed:[],
    showListLock:false,
    linhasSemPo:[],
    linhasSemNF:[],
    shooting:0,
    verbaValor:0,
    verbaData:new Date(),
    verbaPj:0,
    emailAcesso:'',
    colMeio:0,
    colorStatus:['inherit','primary','secondary'],
    fornecedorSend:0,
    valorSend:0,
    temperature:0,
    hide_impostos:false,
    impostosBudget:0,
    valor_custos_extras:0,
    lastLine:0,
    poInterna:false,
    anchorEl:false,
    openDate:false,
    lockId:0,
    dataClose:null,
    verbaPermission:0,
    transfeera_client_id:process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA,
    transfeera_secret:process.env.REACT_APP_TRANSFEERA_SECRET_SANTA,
    cnpjBusca:null,
    listaEmpresas:[],
    empresaCross:0,
    tipoAcesso:false,
    report:[],
    report_date:null,
    block:true,
    showTrack:false,
    api_tipo:0,
    eventos:0,
    fee:0,
    o_valor_nf:0,
    r_valor_nf:0,
    o_valor_nd:0,
    r_valor_nd:0,
    o_valor_int:0,
    r_valor_int:0,
    o_fee:0,
    r_fee:0,
    o_total:0,
    r_total:0,
    o_impostos:0,
    o_total:0,
    r_impostos:0,
    r_total:0,
    ajustesEventos:false
    
  }
 


  // LOADING

  toLoad = () => {
  
    this.setState({
      loadPerc: 2,
      loading:true,
      intervalLoad: setInterval(this.Loader, 200)
    })
  }
   

  endLoad = () => {  
    this.setState({
      loadPerc: 100,
      loading:false,
      intervalLoad:false,
    })
  }

  Loader = () =>{
    if(this.state.loadPerc<100){
        this.setState({
            loadPerc:this.state.loadPerc*1.4,
        })
      }
  }
  
  
  async componentDidMount(){

    //window.location.href = 'https://app.landscapeos.io'
  
    if(this.state.empresa_id/1===23){
      this.setState({
        sub_nucleos_display: ['Pré-Produção','Produçao','Segurança e testes','Equipe','Equipamento de Câmera','Luz e Infra','Arte','Cenografia','Figurino','-','Elenco','Transporte','Alimentação','Acompanhamento'],
    
      })
    }



  
  
    this.setPage();
    this.projectInfo();
    this.reportProjetos()
    
  }

  // ARRAY COMPARE

   compare = ( a, b ) => {
    if ( a.label < b.label ){
      return -1;
    }
    if ( a.label > b.label ){
      return 1;
    }
    return 0;
  }

  
  fromToday =(w) => {
       
    var today = new Date();

    today.setHours(today.getHours() - 3);
    
    var msDiff = today.getTime() - new Date(w).getTime();

    return Math.floor(msDiff / (1000 * 60 * 60 * 24));

  }
 
  // PEGA INFORMAÇÕES DO BUDGET

  projectInfo = async () => {

    this.toLoad()

     // EMPRESAS

      api.get('get/getSelect.php?sheet=empresa&col_id=eventos&id=&empresa_id=no'+'&r='+Math.random(10000)).then(res => {

        let empresas = res.data;
        empresas.sort( this.compare );
        this.setState({
          listaEmpresas:empresas
        })
      })
    
      api.get('get/infoProjetos.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        let showTrack = false
        let block = true;

        if(this.fromToday(result.data.projeto[0].report_date)>0 && result.data.projeto[0].shooting/1===1){
          block = false;
          showTrack = true;
        }
        
        let report = '[{}]';

        if(result.data.projeto[0].report){
          report = result.data.projeto[0].report
        }

          this.setState({
            cliente:result.data.projeto[0].cliente,
            nome:result.data.projeto[0].nome,
            closed:result.data.projeto[0].closed/1,
            closed_date:result.data.projeto[0].closed_date,
            shooting:result.data.projeto[0].shooting,
            blocosAtivos:result.data.ativos,
            api:result.data.projeto[0].api,
            liquor:result.data.liquor,
            impostos:result.data.ativos[0].impostos,
            impostosBudget:result.data.ImpostoBudget,
            impostosValor:result.data.impostosValor,
            transfeera_id:result.data.transfeera_id,
            eventos:result.data.eventos,
            fee:result.data.projeto[0].fee,
            crosscompany:result.data.crosscompany,
            report:report,
            report_date:result.data.projeto[0].report_date,
            block:block,
            showTrack:showTrack
          })


          if(result.data.eventos/1===1){
            this.setState({
               sub_nucleos_display: ['Despesas De Produção Pré-Evento (Vts)','Transporte Terrestre Evento','Equipe De Produção Terceirizada','Locação, Alvara e Taxas','Kits Equipes + Vips','Garagem Equipes','Comissão Técnica + Despesas Relacionadas','Equipe de Servicos Gerais','Estruturas / Gerais','Alimentação','Cenografia e Comunicação Visual','Captains Meeting + After Party']
            })
          }
      

          
          this.getBudgets();
         
      }})
       

  }


        //  REPORT CUSTOS

        reportProjetos = (w) => {
          
          api.get('get/cashflowReport.php?tipo=9&projeto='+this.state.id+'&plano_conta_id='+w+'&start=2000-01-01&end=2050-01-01&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
            if(result.status===200){
              
                let linhas = result.data.linhas;

                let valor_custos_extras = linhas.reduce((a,v) =>  a = a + (v.valor/1) , 0 )*-1

                
              this.setState({
                
                cashflowReportProjeto: result.data.linhas,
                cashflowReportProjetoImpostos: result.data.impostos,
                cashflowReportProjetoEntradas: result.data.entradas,
                valor_custos_extras:valor_custos_extras
                
              })

              this.endLoad()
            }})


          
        }

        

  // LISTA DE ORCAMENTOS


  getBudgets = async () => {
   
    this.toLoad()

    api.get('/get/budgetsProjeto.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
        let budgetClosed = [];

        for(let i=0;i<result.data.budgets.length;i++){
          if(result.data.budgets[i].closed/1===1){
            budgetClosed.push(result.data.budgets[i].id/1)
          }
        }

            this.setState({
              budgets:result.data.budgets,
              budgetClosed:budgetClosed
              
             
        })

          this.getLines()
         
      }

    })
     

  }


  // PEGA LINHAS DO PROJETO

  getLines = async() => {
    this.toLoad()

    api.get('get/linesProjeto.php?id='+this.state.id+'&locked=0&empresa_id='+this.state.empresa_id+'&view='+this.state.view+'&viewB='+this.state.viewB.join(',')+'&liquor='+this.state.liquor+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {

        let ativos = 0;

        if(this.state.ativos===0){
         ativos = [1,0,0,0,0,0,0,0,0,0,0,0,0,0];
        }else{
          ativos = this.state.lastAtivos;
        }

      

        if(this.state.view===1){
          this.setState({
            lastAtivos: this.state.ativos
          })
          ativos = [1,1,1,1,1,1,1,1,1,1,1,1,1,1];

        }
      

        this.setState({
          live_action:result.data[this.state.blocos[0]],
          time_interno:result.data[this.state.blocos[1]],
          fornecedores_post:result.data[this.state.blocos[2]],
          copias:result.data[this.state.blocos[3]],
          ancine:result.data[this.state.blocos[4]],
          api_list:result.data['api'],
          comissao: result.data['comissao'],
          ativos:ativos,
          liquor_api:result.data['liquor'],
          api_tipo:result.data.api_tipo
        
        })
               
        this.endLoad()
        this.calc_ini()
        
        
        

    }})

  }



    
// SALVA PAGINA

setPage = async () => {

    

  api.get('update/setPage.php?id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&page=/projetos&token='+this.state.token+'&r='+Math.random(10000)).then(result => {
      
    if (result.status === 200) {
     
      if(result.data==='LOGOUT'){
        window.location.href='/wrong';
      }
      
    }})
}


// VIEW LINES

viewLines = () =>{
  
    this.setState({
      view:1-this.state.view,
  })
  setTimeout(() => {
    this.getLines();
  }, 20);
  

}


// VIEW LINES BUDGET

viewLinesBudget = (w) =>{
  
  w = w/1;

  let viewB = this.state.viewB;
  
  if(viewB.indexOf(w)>=0){
    viewB.splice(viewB.indexOf(w),1)
  }else{
    viewB.push(w/1)
  }

  this.setState({
    viewB:viewB,
  })

  setTimeout(() => {
    this.getLines();
  }, 20);


}

// VIEW COMENTÁRIOS

viewComentario = () =>{
  
  this.setState({
    comments:1-this.state.comments,
})



}

// VIEW COL MEIO

viewColMeio = () =>{
  
  this.setState({
    colMeio:1-this.state.colMeio,
})



}

// VIEW PERFORMANCE

viewPerformance = () =>{
  
  this.setState({
    temperature:1-this.state.temperature,
    view:1
})

setTimeout(() => {
  this.getLines();
}, 20);


}




// CALCULO INICIAL DA PLANILHA APรS O LOADING

calc_ini = () => {
    
  let sub_nucleos_valor = this.state.sub_nucleos_valor;
  let sub_nucleos_real = this.state.sub_nucleos_real;
  let sub_nucleos_previsto = this.state.sub_nucleos_previsto;
  let valor_live_total = 0;
  let valor_live_previsto = 0;
  let valor_live_real = 0;

  // EVENTOS

  let o_valor_nf = 0;
  let o_valor_nd = 0;
  let o_valor_int = 0;
  let o_impostos = 0;
  let o_fee = 0;
  let o_total = 0;

  let r_valor_nf = 0;
  let r_valor_nd = 0;
  let r_valor_int = 0;
  let r_impostos = 0;
  let r_fee = 0;
  let r_total = 0;

  if(this.state.eventos/1===1){
    this.state.sub_nucleos.map((sub,s)=>{

        this.state.live_action[this.state.sub_nucleos[s]].map((v,o)=>{

          if(v.nf/1===0){
            o_valor_nf+=v.o_total/1;
            r_valor_nf+=v.r_total/1;

          }else{
            o_valor_nd+=v.o_total/1;
            r_valor_nd+=v.r_total/1;
          }

        })

    })

    this.state.fornecedores_post['unico'].map((v,o)=>{
    
      o_valor_int+=v.o_total/1;
      r_valor_int+=v.r_total/1;
    
    })


      o_fee = (o_valor_nf+o_valor_nd)*this.state.fee/100;
      r_fee = (r_valor_nf+r_valor_nd)*this.state.fee/100;

      o_total=o_valor_int+o_valor_nf+o_valor_nd+o_fee;
      r_total=r_valor_int+r_valor_nf+r_valor_nd+r_fee;

      o_impostos = (o_valor_int+o_valor_nf+o_fee)/(1-this.state.impostosBudget/100)-(o_valor_int+o_valor_nf+o_fee);
      o_total += o_impostos;

      r_impostos = (r_valor_int+r_valor_nf+r_fee)/(1-this.state.impostosBudget/100)-(r_valor_int+r_valor_nf+r_fee);
      r_total += r_impostos;

     

      this.setState({
        o_valor_nf:o_valor_nf,
        r_valor_nf:r_valor_nf,
        o_valor_nd:o_valor_nd,
        r_valor_nd:r_valor_nd,
        o_valor_int:o_valor_int,
        r_valor_int:r_valor_int,
        o_fee:o_fee,
        r_fee:r_fee,
        o_total:o_total,
        r_total:r_total,
        o_impostos:o_impostos,
        o_total:o_total,
        r_impostos:r_impostos,
        r_total:r_total
      })


  }

  for(let i=0;i<sub_nucleos_valor.length;i++){
    sub_nucleos_valor[i] = this.state.live_action[this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 )
    sub_nucleos_previsto[i] = this.state.live_action[this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.p_qt/1*v.p_diaria/1*v.p_valor/1) , 0 )
    sub_nucleos_real[i] = this.state.live_action[this.state.sub_nucleos[i]].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 )
    valor_live_total += sub_nucleos_valor[i];
    valor_live_real += sub_nucleos_real[i];
    valor_live_previsto += sub_nucleos_previsto[i];
  };

  
  this.setState({
    sub_nucleos_valor: sub_nucleos_valor,
    sub_nucleos_real: sub_nucleos_real,
    valor_live_total: valor_live_total,
    valor_live_previsto: valor_live_previsto,
    valor_live_real: valor_live_real,
    valor_time_interno:this.state.time_interno['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
    valor_time_interno_real:this.state.time_interno['unico'].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 ),
    valor_fornecedores_post:this.state.fornecedores_post['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
    valor_fornecedores_post_real:this.state.fornecedores_post['unico'].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 ),
    valor_copias:this.state.copias['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
    valor_copias_real:this.state.copias['unico'].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 ),
    valor_ancine:this.state.ancine['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
    valor_ancine_real:this.state.ancine['unico'].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 ),
    valor_api:this.state.api_list['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
    valor_api_real:this.state.api_list['unico'].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 ),
    valor_comissao:this.state.comissao['unico'].reduce((a,v) =>  a = a + (v.o_qt/1*v.o_diaria/1*v.o_valor/1) , 0 ),
    valor_comissao_real:this.state.comissao['unico'].reduce((a,v) =>  a = a + (v.r_qt/1*v.r_diaria/1*v.r_valor/1) , 0 ),
  })

  
}



// ABRE NUCLEO

abreNucleo = (w) => {

  this.toLoad()

  let ativos = this.state.ativos;

  ativos[w] = 1-ativos[w];

  this.setState({
    ativos: ativos,
  })

  setTimeout(() => {
    this.endLoad()
  }, 300);
}


// ABRE DATA

abreData = (k,id,data,nucleo,oid) =>{
 
  if(this.state.closed===0){
    
    this.setState({
      dataAberta:data,
      dataOpen:true,
      qualData:id,
      dataGrupo:k,
      dataNucleo:nucleo,
      dataLineObj:oid,
      loading:false
    })
  }
  
}



// FORMAT DATE

formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}


   // FORMAT DATE

   formatDate2 = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()
        
        ;

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}


// FECHA DATA

fechaDialog = () =>{
 
  this.setState({
    dataOpen:false,
    cnpjOpen:false,
    loading:false,
    mostraCNPJ:false,
    verbaOpen:false,
    acessoOpen:false,
    sendOpen:false,
    abreDialogFone:false,
    fornecedorNovo:0,
    showListLock:false,
    abreInfoPo:false,
    testeOpen:false,
    openDate:false,
    ajustesEventos:false


  })
}

// MUDA DATA

changeData = (e) =>{
  e = this.formatDate(e);
  
  this.setState({
    dataAberta:e
  })
}


// MUDA DATA

changeDataFechamento = (e) =>{
  e = this.formatDate(e);
  
  this.setState({
    dataClose:e
  })
}

// SALVA DATA

saveData = () =>{
  
  if(this.state.dataAberta!==0){

    api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+this.state.dataAberta+'&id='+this.state.qualData+'&sheet=cost&col=dt_prevista&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.setState({
        dataOpen:false
        
      })

      
      if(this.state.dataGrupo===0){
        
        let live_action = this.state.live_action;
        live_action[this.state.sub_nucleos[this.state.dataNucleo]][this.state.dataLineObj]['dt_prevista'] = this.state.dataAberta;
        this.setState({
          live_action:live_action
        })

      }

      if(this.state.dataGrupo===1){

        let fornecedores_post = this.state.fornecedores_post;
        fornecedores_post['unico'][this.state.dataLineObj]['dt_prevista']  = this.state.dataAberta;
        this.setState({
          fornecedores_post:fornecedores_post
        })

      }

      if(this.state.dataGrupo===2){

        let api_list = this.state.api_list;
        api_list['unico'][this.state.dataLineObj]['dt_prevista']  = this.state.dataAberta;
        this.setState({
          api_list:api_list
        })

      }

      if(this.state.dataGrupo===3){

        let comissao = this.state.comissao;
        comissao['unico'][this.state.dataLineObj]['dt_prevista']  = this.state.dataAberta;
        this.setState({
          comissao:comissao
        })

      }

      if(this.state.dataGrupo===5){

        let ancine = this.state.ancine;
        ancine['unico'][this.state.dataLineObj]['dt_prevista']  = this.state.dataAberta;
        this.setState({
          ancine:ancine
        })

      }
      
      

    }})
        
  }else{
    alert('Defina uma data!')
  }
}


// DUPLICAR LINHAS

duplicarLinhas = (w) => {
  if(this.state.closed===0){
  api.get('insert/duplicarLinha.php?line_id='+w+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      this.getLines()
    }})
  }
}


// EDIT

toEdit = (budget_id,w,e,api) => {

    if(this.state.budgetClosed.indexOf(budget_id/1)<0){
    if(e){
    
      if(e===this.state.empresa_id || this.state.empresa_id/1===2 || api===1){
        if(this.state.closed===0){
          this.setState({
            edit:w
          })
        }
      }else{
        alert('Você está logado em outra unidade.')
      }
    }
}


}

// FIELD BLUR

fieldBlur = (w,e,id,tipo,bloco,item) => {

  if(e.target.value!==""){

    let total = 0;

    let live_action = 0;
    let time_interno = 0;
    let fornecedores_post = 0;
    let copias = 0;
    let ancine = 0;
    let api_list = 0;
    let comissao = 0;
    let wline = 'r';

    if(tipo===0){

      live_action = this.state.live_action;

      live_action[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = live_action[bloco][item]['r_qt']/1*live_action[bloco][item]['r_diaria']/1*live_action[bloco][item]['r_valor']/1;

      live_action[bloco][item]['r_total'] = total;

    }

    if(tipo===6){

      live_action = this.state.live_action;

      live_action[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = live_action[bloco][item]['p_qt']/1*live_action[bloco][item]['p_diaria']/1*live_action[bloco][item]['p_valor']/1;

      live_action[bloco][item]['p_total'] = total;
      wline = 'p';

    }

    if(tipo===8){

      live_action = this.state.live_action;

    
      live_action[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = live_action[bloco][item]['o_qt']/1*live_action[bloco][item]['o_diaria']/1*live_action[bloco][item]['o_valor']/1;

      live_action[bloco][item]['o_total'] = total;
      wline = 'o';

    }

    if(tipo===1){

      time_interno = this.state.time_interno;

      time_interno[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = time_interno[bloco][item]['r_qt']/1*time_interno[bloco][item]['r_diaria']/1*time_interno[bloco][item]['r_valor']/1;

      time_interno[bloco][item]['r_total'] = total;

    }

    if(tipo===2){

      fornecedores_post = this.state.fornecedores_post;

      fornecedores_post[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = fornecedores_post[bloco][item]['r_qt']/1*fornecedores_post[bloco][item]['r_diaria']/1*fornecedores_post[bloco][item]['r_valor']/1;

      fornecedores_post[bloco][item]['r_total'] = total;

      wline = 'r';

    }

    if(tipo===3){

      copias = this.state.copias;

      copias[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = copias[bloco][item]['r_qt']/1*copias[bloco][item]['r_diaria']/1*copias[bloco][item]['r_valor']/1;

      copias[bloco][item]['r_total'] = total;

    }


    if(tipo===4){

      ancine = this.state.ancine;

      ancine[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = ancine[bloco][item]['r_qt']/1*ancine[bloco][item]['r_diaria']/1*ancine[bloco][item]['r_valor']/1;

      ancine[bloco][item]['r_total'] = total;

    }

    if(tipo===5){

      api_list = this.state.api_list;

      api_list[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = api_list[bloco][item]['r_qt']/1*api_list[bloco][item]['r_diaria']/1*api_list[bloco][item]['r_valor']/1;

      api_list[bloco][item]['r_total'] = total;

    }


    if(tipo===7){

      comissao = this.state.comissao;

      comissao[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = comissao[bloco][item]['r_qt']/1*comissao[bloco][item]['r_diaria']/1*comissao[bloco][item]['r_valor']/1;

      comissao[bloco][item]['r_total'] = total;

    }

    if(tipo===9){

      fornecedores_post = this.state.fornecedores_post;

      fornecedores_post[bloco][item][this.state.fields[w]] = e.target.value;
      
      total = fornecedores_post[bloco][item]['o_qt']/1*fornecedores_post[bloco][item]['o_diaria']/1*fornecedores_post[bloco][item]['o_valor']/1;

      fornecedores_post[bloco][item]['o_total'] = total;

      wline = 'o';

    }

    if(tipo===10){

      live_action = this.state.live_action;

      live_action[bloco][item]['nome'] = e.target.value;
      

      wline = 'nome';


    }




  api.get('update/updateLineCost.php?valor='+e.target.value+'&w='+wline+'&col='+this.state.fields[w]+'&id='+id+'&total='+total+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      

  if(tipo===0 || tipo===6 || tipo===8 || tipo===10){
    this.setState({
      edit:0,
      live_action:live_action
    })
  }


  if(tipo===1){
    this.setState({
      edit:0,
      time_interno:time_interno
    })
  }

  if(tipo===2 || tipo===9){
    this.setState({
      edit:0,
      fornecedores_post:fornecedores_post
    })
  }

  if(tipo===3){
    this.setState({
      edit:0,
      copias:copias
    })
  }

  if(tipo===4){
    this.setState({
      edit:0,
      ancine:ancine
    })
  }

  if(tipo===5){
    this.setState({
      edit:0,
      api_list:api_list
    })
  }

  if(tipo===7){
    this.setState({
      edit:0,
      comissao:comissao
    })
  }



  setTimeout(() => {
    this.calc_ini()
  }, 10);

  }})



}else{
  
  this.setState({
    edit:0,
    
  })
}
  

}

// SELECIONA PJ

mostraDadosCNPJ = (e) => {

 
  if(this.state.closed===0){
    this.toLoad()
    api.get('update/update.php?sheet=cost&id='+this.state.lineId+'&col=pj_id&value='+e+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
          

          this.setState({

            mostraCNPJ:false,
            cnpjOpen:false,
            fornecedorAtivo:e
            
          })

          this.endLoad()

          //this.getLines()

          this.updateLinesCNPJ(this.state.grupoId,this.state.nucleoId,this.state.lineObjId,e)
    
    }});
  }
}

// UPDATE LINES


updateLinesCNPJ = (grupoId,nucleoId,lineObjId,pj_id) => {

  if(this.state.grupoId===0){

    let live_action = this.state.live_action;
    live_action[this.state.sub_nucleos[this.state.nucleoId]][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      live_action:live_action
    })

  }

  if(this.state.grupoId===1){

    let fornecedores_post = this.state.fornecedores_post;
    fornecedores_post['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      fornecedores_post:fornecedores_post
    })

  }

  if(this.state.grupoId===2){

    let api_list = this.state.api_list;
    api_list['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      api_list:api_list
    })

  }

  if(this.state.grupoId===3){

    let comissao = this.state.comissao;
    comissao['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      comissao:comissao
    })

  }

  if(this.state.grupoId===5){

    let ancine = this.state.ancine;
    ancine['unico'][this.state.lineObjId]['pj_id'] = pj_id;
    this.setState({
      ancine:ancine
    })

  }

}

// ABRE CNPJ


abreCNPJ = (w,id,grupoId,nucleoId,lineObjId) => {

  if(id==="0"){
 
  this.setState({
    
    cnpjOpen:true,
    lineId:w,
    fornecedorNovo:0,
    grupoId:grupoId,
    nucleoId:nucleoId,
    lineObjId:lineObjId,
   
  })

}else{

  api.get('get/getPj.php?empresa_id='+localStorage['empresa_id']+'&id='+id+'&sheet=users&col_id=id&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      
  this.setState({
    fornecedor:result.data,
    cnpjOpen:true,
    lineId:w,
    mostraCNPJ:true,
    fornecedorNovo:2,
   
  })


}})
}

}


// NOVO PJ
novoCNPJ = (e) => {

  
  let fornecedor = this.state.fornecedor;

  let confereEmail = e.split('-');
 
        if(confereEmail[2]==='' || confereEmail[3]===''){

            alert('Fornecedor sem e-mail ou celular. Você precisa atualizar o cadastro');

            api.get('update/update.php?sheet=cost&id='+this.state.lineId+'&col=pj_id&value=0&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
              if (result.status === 200) {
        
                    setTimeout(() => {
                      
                      this.abreCNPJ(this.state.lineId,this.state.fornecedorAtivo,this.state.grupoId,this.state.nucleoId,this.state.lineObjId);

                  }, 10);
            
            }});

            
        }

  if(this.state.cnpjOpen || this.state.cnpjOpen2){

      let info = 'nome';
      if(e.split('@').length>1){
        info = 'email';
      }

      if(e.split('.')[0]/1>0){
        info = 'cnpj';
      }

    
      
      
      if(e!=="Novo"){

        this.setState({
          update:this.state.update+1,
          fornecedor:fornecedor,
          mostraCNPJ:true,
          mostraBuscaCNPJ:false,
          
        })
        
      }else{

        api.get('update/update.php?sheet=cost&id='+this.state.lineId+'&col=pj_id&value=0&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {

            this.setState({
              update:this.state.update+1,
              fornecedor:fornecedor,
              //mostraCNPJ:true,
              mostraBuscaCNPJ:true,
              fornecedorNovo:1,
            })
            this.getLines()
          }})
      }


       
        
        
        }

        
        
}


// NOVO PJ VERBA
novoCNPJVerba = () => {

  let fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":"","email":"","senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":"","cnpj":"","salario":"0","razao_social":"","inscricao_estadual":"","fone":"","endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0"}]
  this.setState({
    fornecedor:fornecedor,
    mostraBuscaCNPJ:true,
    fornecedorNovo:1,
  })

}


// ALTERA DADOS FORNECEDOR

dadosFornecedor = (e,w) =>{
  
  if(e.target.value!==""){
    let fornecedor = this.state.fornecedor;
    fornecedor[0][w]=e.target.value;


    if(w==='cnpj' || w==='fone' || w==='cpf'){
      fornecedor[0][w] = fornecedor[0][w].replace(/\D/g, "");
    }

   
    this.setState({
      fornecedor:fornecedor,
    
    })

    }
  }


// UPDATE CADASTRO

updateFornecedor = () => {

  
  if(this.state.fornecedor[0]['email'] && this.state.fornecedor[0]['cnpj'] && this.state.fornecedor[0]['razao_social']){
 
    api.post('update/updateRowCNPJ.php?r='+Math.random(10000),JSON.stringify({ 
      
      sheet:'users',
      line_id:this.state.lineId,
      dados: this.state.fornecedor
    
    })).then(result => {
          
      if (result.status === 200) {
        if(result.data==='update'){
          this.getLines();
          alert('CNPJ já existente no banco. Busque pela razão social ou e-mail.')
        }else{
          let live_action = this.state.live_action;
          live_action[this.state.sub_nucleos[this.state.nucleoId]][this.state.lineObjId]['pj_id'] = this.state.fornecedor[0]['id'];
          this.setState({
            live_action:live_action
          })
          
          this.setState({
            cnpjOpen:false,
          
          })
          this.endLoad()
        }
      }}
      )
    }else{
      alert('CNPJ, Razão Social e E-mail são obrigatórios.')
    }
}

// CADASTRAR


cadastraFornecedor = () => {

  let fornecedor = this.state.fornecedor;

  if(this.state.closed===0){
    
    if(fornecedor[0]['cnpj']!=='' && fornecedor[0]['razao_social']!=='' && fornecedor[0]['email']!=='' && fornecedor[0]['fone']/1>0){
    
      api.get('insert/insert.php?id=1&sheet=users&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
        
        fornecedor[0]['id'] = result.data;
        

        api.get('update/update.php?sheet=cost&id='+this.state.lineId+'&col=pj_id&value='+result.data+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
          if (result.status === 200) {
              
            this.setState({fornecedor:fornecedor,
                mostraCNPJ:false,
                fornecedorNovo:0,
              })

              setTimeout(() => {
                this.updateFornecedor();
              }, 50);
              
              setTimeout(() => {

                this.getLines()
              
              }, 2000);
              

            }})
            
          }})
        

    }else{
      alert('Você precisa preencher Razão Social, CNPJ, E-mail e Celular!')
    }

  }
}


// CADASTRAR


cadastraFornecedorVerba = () => {

  let fornecedor = this.state.fornecedor;

    if(fornecedor[0]['cnpj']!=='' && fornecedor[0]['razao_social']!=='' && fornecedor[0]['email']!==''){
    
      api.get('insert/insert.php?id=1&sheet=users&col_id=tipo&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {

        this.setState({
          fornecedor:[],
          mostraCNPJ:false,
          fornecedorNovo:0,
        })

      }})
    }
  
}


// ENVIA PO

enviaPO = (w,wapi,grupoId,nucleoId,lineObjId) => {


  this.toLoad()
  let fornecedorSend=0;

  api.get('get/get.php?id='+w+'&sheet=cost&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      if(result.data[0]['r_total']!=='0'){

        if(wapi===1){
          result.data[0]['pj_id']=15165;
        }

      if(result.data[0]['pj_id']==='0'){
        this.abreCNPJ(w,'0',grupoId,nucleoId,lineObjId);
      }else{

        
        api.get('get/getPj.php?id='+result.data[0]['pj_id']+'&sheet=users&col_id=id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result2 => {
        
          if (result2.status === 200) {

            if(result2.data[0]['fone']){
            fornecedorSend=result2.data[0]['razao_social']+' - '+result2.data[0]['cnpj'];

                if(!result.data[0]['dt_prevista']){
                  this.abreData(w);
                }else{


                  this.setState({
                    grupoId:grupoId,
                    nucleoId:nucleoId,
                    lineObjId:lineObjId,
                    valorSend: result.data[0]['r_total'],
                    fornecedorSend:fornecedorSend,
                    loading:false,
                    sendOpen:true,
                    linhaSend:w
                  })
                  
                  

                }
                

              }else{
                this.abreCelular(result.data[0]['pj_id'])
              }
      }})



      }
    }else{
      alert('O valor realizado não pode ser zero.')
    }

  




    }})
}

// MOSTRA PO INFO

mostraPoInfo = (id) => {

  api.get('get/getPoInfo.php?id='+id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      this.setState({
        infoPo:result.data,
        abreInfoPo:true,
      })

    }})

}

// SETA EMPRESA CROSSCOMPANY

setEmpresa = (e) => {

  this.setState({
    empresaCross:e
  })
}

// ENVIA PO SERVER


enviaPOGo = () => {

  this.toLoad()
  
  if(this.state.linhaSend!==this.state.lastLine){

        let poInt = 0;

        if(this.state.poInterna){
          
          poInt=1
          
        }
        let linkSendTo='sendToCashflow';
        
        if(this.state.grupoId===2){
          linkSendTo='sendToCashflowAPI';
        }


        api.get('insert/'+linkSendTo+'.php?id='+this.state.linhaSend+'&usuario_id='+this.state.usuario_id+'&po_interna='+poInt+'&empresa_cross='+this.state.empresaCross+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {

          if (result.status === 200) {
            
            console.log(result.data)
            this.setState({
              sendOpen:false,
              loading:false,
              lastLine:this.state.linhaSend,
              poInterna:false
            })


            if(this.state.grupoId===0){

              let live_action = this.state.live_action;
              live_action[this.state.sub_nucleos[this.state.nucleoId]][this.state.lineObjId]['status'] = 1;
              this.setState({
                live_action:live_action
              })

            }

            if(this.state.grupoId===1){

              let fornecedores_post = this.state.fornecedores_post;
              fornecedores_post['unico'][this.state.lineObjId]['status'] = 1;
              this.setState({
                fornecedores_post:fornecedores_post
              })

            }

            if(this.state.grupoId===2){

              let api_list = this.state.api_list;
              api_list['unico'][this.state.lineObjId]['status'] = 1;
              this.setState({
                api_list:api_list
              })

            }

            if(this.state.grupoId===3){

              let comissao = this.state.comissao;
              comissao['unico'][this.state.lineObjId]['status'] = 1;
              this.setState({
                comissao:comissao
              })

            }

            //this.getLines();

          }
          

        })
      }else{
        alert('PO já enviada')
      }

}


// VIEW SHEET

viewSheet = (w) => {

  if(this.state.api_tipo/1===2){
    alert('Em APITopline o budget precisa ser verificado na operação original.')
    }else{
    if(w<=19790 && w>=10000){
      window.open('https://treatments.run/santapv/budget/Sheet_2020.php?orcid='+w);
    }else{
      window.open('/sheet/'+w);
    }
}
}


// XLS EQUIP

listaEquipe = () =>{
  window.open('https://treatments.run/landscape/get/xls_equipe.php?projeto_id='+this.state.id+'&r='+Math.random())
  this.setState({
    anchorEl:null
  })
}


// NAVEGAR TECLADO

handleKeyDown = (e,blocoOriginal,index,col,id,w) => {

  // TAB
 

  if(e.key==='Tab'){

    
    let dataArray = [];

    let bloco = "";
    
    if(w===0 || w===6){
      dataArray = this.state.live_action;
      bloco = this.state.sub_nucleos[blocoOriginal];
    }

    e.preventDefault();
    
    let addCol='';
   
   if(w===6){
    addCol='p';
   }
      if((col+1)<3){
        
        this.setState({
          edit: dataArray[bloco][index]['id']+'_'+addCol+this.state.fields[col+1].split('_')[1]
        })


      }else{
        if(dataArray[bloco][index+1]){
          
          this.setState({
            edit: dataArray[bloco][index+1]['id']+'_'+addCol+this.state.fields[0].split('_')[1]
          })
        }
      }

  



    if(e.target.value!==""){

      dataArray[bloco][index][this.state.fields[col]]=e.target.value;
      let total = 0;
      if(w<6){
      total = dataArray[bloco][index]['r_qt']/1*dataArray[bloco][index]['r_diaria']/1*dataArray[bloco][index]['r_valor']/1;
      dataArray[bloco][index]['r_total'] = total;
      }else{
    
        total = dataArray[bloco][index]['p_qt']/1*dataArray[bloco][index]['p_diaria']/1*dataArray[bloco][index]['p_valor']/1;
        dataArray[bloco][index]['p_total'] = total;
    
      }
    
    
      api.get('update/updateLineCost.php?valor='+e.target.value+'&w=r&col='+this.state.fields[col]+'&id='+id+'&total='+total+'&r='+Math.random(10000)).then(result => {
          
        if (result.status === 200) {
    
               this.setState({
                live_action:dataArray
              })
    
        }})
      }

      
    
    
  }


 
  if(e.keyCode >=37 && e.keyCode <=40){

    let dataArray = [];

    let bloco = "";

    if(w===0 || w===6){
      dataArray = this.state.live_action;
      bloco = this.state.sub_nucleos[blocoOriginal];
    }

 
    if(w===2){
      dataArray = this.state.fornecedores_post;
      bloco='unico';
    }

    if(w===3){
      dataArray = this.state.copias;
      bloco='unico';
    }

    if(w===4){
      dataArray = this.state.ancine;
      bloco='unico';
    }

    if(w===5){
      dataArray = this.state.api_list;
      bloco='unico';
    }

    if(w===7){
      dataArray = this.state.comissao;
      bloco='unico';
    }



    

    
  

  if(e.keyCode === 40){
   //baixo

    if(dataArray[bloco][index+1]){
      this.setState({
        edit: dataArray[bloco][index+1]['id']+'_'+this.state.fields[col].split('_')[1]
      })
    }else{
        if(dataArray[bloco] && blocoOriginal!=='unico'){
          bloco = this.state.sub_nucleos[blocoOriginal+1];
          this.setState({
          edit: dataArray[bloco][0]['id']+'_'+this.state.fields[col].split('_')[1]
        })
        }
      }
    }

  if(e.keyCode === 38){
    // cima

    if(dataArray[bloco][index-1]){
      this.setState({
        edit: dataArray[bloco][index-1]['id']+'_'+this.state.fields[col].split('_')[1]
      })
    }else{
      if(dataArray[this.state.sub_nucleos[blocoOriginal-1]] && blocoOriginal!=='unico'){
        
        index = dataArray[this.state.sub_nucleos[blocoOriginal-1]].length-1;
        bloco = this.state.sub_nucleos[blocoOriginal-1];
        this.setState({
          edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col].split('_')[1]
        })
      }
    }

      
  }

  if(e.keyCode === 39){
    if((col+1)<3){
      this.setState({
        edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col+1].split('_')[1]
      })
    }
  }

  if(e.keyCode === 37){
    if((col-1)>=0){
      this.setState({
        edit: dataArray[bloco][index]['id']+'_'+this.state.fields[col-1].split('_')[1]
      })
    }
     
      
  }

  if(e.target.value!==""){

    dataArray[bloco][index][this.state.fields[col]]=e.target.value;
    let total = 0;
    if(w<6){
    total = dataArray[bloco][index]['r_qt']/1*dataArray[bloco][index]['r_diaria']/1*dataArray[bloco][index]['r_valor']/1;
    dataArray[bloco][index]['r_total'] = total;
    }else{
  
      total = dataArray[bloco][index]['p_qt']/1*dataArray[bloco][index]['p_diaria']/1*dataArray[bloco][index]['p_valor']/1;
      dataArray[bloco][index]['p_total'] = total;
  
    }
  
  
    api.get('update/updateLineCost.php?valor='+e.target.value+'&w=r&col='+this.state.fields[col]+'&id='+id+'&total='+total+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
  
             this.setState({
              live_action:dataArray
            })
  
      }})
    }

  
}


  
}

// DATE LOCK

showlock(id){

  this.setState({
    lockId:id,
    openDate:true
  })
  
}
// SHOW LOCK

tolock = (id) => {

  this.toLoad()

  this.setState({
    closeBudget:id
  })

  api.get('get/poSemNF.php?id='+id+'&r='+Math.random(10000)).then(result => {

    
    this.setState({
      linhasSemNF:result.data,
    })
    

    if(result.data.length>0){
      this.setState({
        showListLock:true
        
      })

      this.endLoad()
    }

      this.tolockConferePO(id)
    
    
  })
 
}

tolockConferePO = (id) => {
  
  let liveAction = this.state.live_action;
  let fornecedoresPost = this.state.fornecedores_post;
  let comissao = this.state.comissao;

  let linhasSemPo = [];
  for(let i=0;i<this.state.sub_nucleos.length;i++){

    for(let l=0;l<liveAction[this.state.sub_nucleos[i]].length;l++){
      if(id){
        if(liveAction[this.state.sub_nucleos[i]][l]['r_total']>0 && liveAction[this.state.sub_nucleos[i]][l]['status']/1===0 && liveAction[this.state.sub_nucleos[i]][l]['budget_id']/1===id/1){
          linhasSemPo.push(liveAction[this.state.sub_nucleos[i]][l])
        }

      }else{
        if(liveAction[this.state.sub_nucleos[i]][l]['r_total']>0 && liveAction[this.state.sub_nucleos[i]][l]['status']/1===0){
          linhasSemPo.push(liveAction[this.state.sub_nucleos[i]][l])
        }
      }
    }

  }

  // FORNECEDORES

  for(let i=0;i<fornecedoresPost['unico'].length;i++){

  if(id){
        if(fornecedoresPost['unico'][i]['r_total']>0 && fornecedoresPost['unico'][i]['status']/1===0 && fornecedoresPost['unico'][i]['budget_id']/1===id/1){
          linhasSemPo.push(fornecedoresPost['unico'][i])
        }

      }else{
        if(fornecedoresPost['unico'][i]['r_total']>0 && fornecedoresPost['unico'][i]['status']/1===0){
          linhasSemPo.push(fornecedoresPost['unico'][i])
        }
      }

  }

  // COMISSõES

  for(let i=0;i<comissao['unico'].length;i++){

    if(id){
          if(comissao['unico'][i]['r_total']>0 && comissao['unico'][i]['status']/1===0 && comissao['unico'][i]['budget_id']/1===id/1){
            linhasSemPo.push(comissao['unico'][i])
          }
  
        }else{
          if(comissao['unico'][i]['r_total']>0 && comissao['unico'][i]['status']/1===0){
            linhasSemPo.push(comissao['unico'][i])
          }
        }
  
    }

  if(linhasSemPo.length===0 && this.state.linhasSemNF.length===0){

    if(id){
      this.lockBudget(id)
    }else{
      this.lock()
    }
    
  }else{
    this.setState({
      openDate:false,
      showListLock:true,
      linhasSemPo:linhasSemPo,
      closeBudget:id
    })
  }
}

// LOCK

lock = () => {

  let data = this.state.dataClose
  if(!data){

    data = new Date();
    data = this.formatDate(data)

  }

  if(this.state.closeBudget){
    this.setState({
      closeBudget:null
    })
    this.lockBudget(this.state.closeBudget);
  }else{
  if(this.state.closed===0){
    
    
    api.get('update/closeProject.php?id='+this.state.id+'&data='+data+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
          
          if (result.status === 200) {
              
            this.setState({
              closed:1,
              showListLock:false,
              openDate:false
            })

            this.projectInfo();

            }})

            
      }
    }
      

}

// LOCK BUDGET

lockBudget = (id) => {

  this.toLoad()
  if(this.state.closed===0){
 api.get('update/closeBudget.php?id='+id+'&data='+this.state.dataClose+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
        this.setState({
          closed:1,
          showListLock:false,
          openDate:false
        })

        this.projectInfo();

        }})
  }
      
}


// SHOOTING

shooting = () => {
    
    api.get('update/update.php?sheet=projeto&id='+this.state.id+'&col=shooting&value='+(1-this.state.shooting)+'&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
      
      if (result.status === 200) {
          
        this.setState({shooting:1-this.state.shooting})

        }})
  
}


// OPEN DIALOG ACESSOS


openAcessos = () => {
  this.toLoad()
  api.get('get/get.php?id='+this.state.id+'&sheet=acesso&col_id=projeto_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      
      this.setState({

        listAcessos:result.data,
        acessoOpen:true,
        anchorEl:null

      })

      this.endLoad()
    }})

}

// VERBA

liberarVerba(id){

  this.toLoad();

  api.get('insert/liberarVerba.php?id='+id+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

        let sms = result.data

          axios({
            method: 'post',
            url: 'https://v1.nocodeapi.com/gustavogripe/twilio/FEltElVkNCZljFRG/sendSMS', 
            params: {},
            data: {"body":"Landscape - "+sms.pass+" é código de acesso para a verba do projeto "+sms.projeto,"to":'+55'+sms.nr,"from":"+19894557682"}
          }).then(result => {
          
          })

        this.openVerba()
        this.getLines()
        this.endLoad()

        alert('O fornecedor receberá o link por e-mail e a senha por SMS.')

    }})

}

// TRAVAR VERBA

travarVerba(id){

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value=1&id='+id+'&sheet=verba&col=status&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
     
      this.openVerba()

    }
  })

}


// OPEN DIALOG VERBA

openVerba = () => {

  this.setState({
    verbaOpen:true,
    anchorEl:false
  })

  api.get('get/getVerbas.php?usuario_id='+this.state.usuario_id+'&id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      
      this.setState({

        listVerbas:result.data.verbas,
        verbaPermission:result.data.cargo

      })

      this.endLoad()
    }})


  

}

// UPDATE VERBA

upVerba = (e,w) => {

  

  if(w==='data'){
    
    this.setState({verbaData:e})
  }

  if(w==='valor'){
    this.setState({verbaValor:e.target.value})
  }

  if(w==='plano'){
    
    this.setState({verbaPlano:e})
  }

  if(w==='pj'){
    
    api.get('get/get.php?empresa_id=no&sheet=users&col_id=id&id='+e+'&r='+Math.random(10000)).then(result => {


      let pj = result.data[0];

      if(pj.fone && pj.email){
        this.setState({verbaPj:e})
      }else{
        alert('O fornecedor precisa ter telefone e e-mails válidos.')
        this.setState({
          verbaOpen:false
        })

        setTimeout(() => {
          this.setState({
            verbaOpen:true
          })
        }, 30);
      }

    })
   


  }

  if(w==='cpf'){
    
    this.setState({verbaCPF:e})
  }

  if(w==='tipo_chave'){
    
    this.setState({verbaChave:e})
  }

  if(w==='pix'){
    
    this.setState({verbaPix:e})
  }

}

dadosPF = () => {

  if(this.state.verbaPj && this.state.verbaValor && this.state.verbaPj && this.state.verbaPlano){
    this.setState({
      mostraDadosPF:true
    })
  }else{
    alert('Você precisa preencher a verba, a data, o tipo da verba e os dados do fornecedor!')
  }

}

// SOLICITAR VERBA

solicitarVerba = (w) => {


  let goVerba = false;

  if(w===1){
    if(this.state.verbaCPF && this.state.verbaChave && this.state.verbaPix){
      goVerba = true;
    }
  }else{
    if(this.state.verbaPj && this.state.verbaValor && this.state.verbaPj && this.state.verbaPlano){
      goVerba = true;
    }
  }

    if(goVerba){

      let verbaURL='solicitaVerba';
      
      if(this.state.transfeera_id>0){
        verbaURL='solicitaVerbaPix';
      }

      let valor = this.state.verbaValor.replace(',','');
      let dataFormatada = (this.state.verbaData.getFullYear() + "-" + ((this.state.verbaData.getMonth() + 1)) + "-" + (this.state.verbaData.getDate() )) ; 
        
      api.get('insert/'+verbaURL+'.php?w='+w+'&id='+this.state.id+'&usuario_id='+this.state.usuario_id+'&data='+dataFormatada+'&valor='+valor+'&plano='+this.state.verbaPlano+'&pj='+this.state.verbaPj+'&pix='+this.state.verbaPix+'&cpf='+this.state.verbaCPF+'&tipo_chave='+this.state.verbaChave+'&r='+Math.random(10000)).then(result => {
            
        if (result.status === 200) {
          
          this.fechaDialog()
          this.getLines()
          this.setState({
            mostraDadosPF:false
          })
          
        }})
      
      }else{

        alert('Você precisa preencher todos os dados.');

      }
      

}


// OPEN TESTE ELENCO

openVerbaElenco = () => {

  this.setState({
    testeOpen:true,
    anchorEl:false
  })

  api.get('get/getTestes.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      let transfeera_client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SANTA;
      let transfeera_secret = process.env.REACT_APP_TRANSFEERA_SECRET_SANTA;

      if(result.data.cnpj_produtora!=='07842589000114'){

        transfeera_client_id = process.env.REACT_APP_TRANSFEERA_CLIENT_ID_SAM
        transfeera_secret = process.env.REACT_APP_TRANSFEERA_SECRET_SAM

      }


      this.setState({

        listTestes:result.data.testes,
        transfeera_client_id:transfeera_client_id,
        transfeera_secret:transfeera_secret
        

      })

      this.endLoad()
    }})

}

// UPDATE ACESSO 

upEmailAcesso = (e) => {
  
  this.setState({emailAcesso:e.target.value})
}

//DAR ACESSO

darAcesso = () => {
  if(this.state.emailAcesso===''){
    alert('Preencha o e-mail!')
  }else{
    this.toLoad()
    let tipo = 0;

    if(this.state.tipoAcesso){
      tipo=1
    }
    api.get('insert/newAcesso.php?email='+this.state.emailAcesso+'&projeto_id='+this.state.id+'&empresa_id='+this.state.empresa_id+'&tipo='+tipo+'&r='+Math.random(10000)).then(result => {
        
      if (result.status === 200) {
          
        
        this.setState({listAcessos:result.data})

        }})
        this.endLoad()
  }
}

// DELETAR ACESSO


deletaAcesso = (w) => {

  this.toLoad()
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value=1&id='+w+'&sheet=acesso&col=active&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
      
      api.get('get/get.php?id='+this.state.id+'&sheet=acesso&col_id=projeto_id&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result => {
        
        if (result.status === 200) {
    
          
          this.setState({
    
            listAcessos:result.data,
            
    
          })

          this.endLoad()
    
        }})

      

    }})  
}



// TRAVAR LINHA

travarLinha = (id,w,dataNucleo,dataLineObj) => {

  this.toLoad();

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+w+'&id='+id+'&sheet=cost&col=locked&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

        let live_action = this.state.live_action;
        live_action[this.state.sub_nucleos[dataNucleo]][dataLineObj].locked = w;
        this.setState({
          live_action:live_action
        })

        this.endLoad();
      this.getLines();

  }})

}


// XRAY

xray = () => {

  window.open('/xray/'+this.state.id+'_'+this.state.empresa_id);

  this.setState({
    anchorEl:null
  })

}


// ABRE CELULAR

abreCelular = (id) =>{
  this.setState({
    celularPjId:id,
    abreDialogFone:true
  })
}

// DAODS CELULAR

setaCelular = (e) => {
  this.setState({
    celularPJ:e.target.value
  })
}

// SALVA CELULAR

saveCelular = () => {
  
  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+this.state.celularPJ+'&id='+this.state.celularPjId+'&sheet=users&col=fone&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      this.setState({
        abreDialogFone:false
      })

  }})


}

// SWITCH

swPOInterna = (e) => {
 
  this.setState({
      poInterna:e.target.checked
  })
}

// ABRE MENU

abreMenu = (e) => {
  this.setState({
    anchorEl:e.currentTarget,
    
  })
}

// FECHA MENu

fechaMenu = () => {

  this.setState({
    anchorEl:null
  })
}

// VERBA

deletaCacheTeste(id){

  if(this.state.permission<3){
      this.toLoad();

      api.get('delete/delete.php?sheet=cache_teste&id='+id+'&r='+Math.random(10000)).then(result => {
            
        if (result.status === 200) {

          this.getTestes()
          this.endLoad()
        }})
    }else{
      alert('Você não tem permissão para deletar.')
    }

}


// VIEW TRACK

viewTrack = () => {
  this.setState({
    showTrack:1-this.state.showTrack
  })
}

// TOKEN PIX

tokenPix = () => {

  if(this.state.token_transfeera){
    this.criarLote()
    
  }else{
   
  axios({
    method: 'post',
    url: 'https://login-api.transfeera.com/authorization', 
    params: {
            "grant_type": "client_credentials",
            "client_id": this.state.transfeera_client_id,
            "client_secret": this.state.transfeera_secret
    },
    data: {
            "grant_type": "client_credentials",
            "client_id": this.state.transfeera_client_id,
            "client_secret": this.state.transfeera_secret
    }
  }).then((response) => {
        

        // handle success
        
        this.setState({
          token_transfeera:response.data.access_token
        })

        
        this.criarLote()

      
        
  }).catch(function (error) {
        // handle error
        console.log(error)
  })

}
}


// CRIAR LOTE
criarLote = (w,dt) => {

  axios({
    method: 'post',
    url: 'https://api.transfeera.com/batch', 
    headers: {
            "Authorization": 'Bearer '+this.state.token_transfeera,
            "User-Agent": "Santa Transmedia (giovanna@sant.at)",
            "Content-Type": "application/json"
    },
    data: {
            "name": 'Cachê Teste - '+this.state.pixProjeto,
            
    }
  }).then((response) => {
        // handle success
        
        
        this.setState({
            id_lote:response.data.id
        })

        setTimeout(() => {

          this.criarPix(w,dt);
        }, 10);
       

       
  }).catch(function (error) {
        // handle error
        let errorAlert = [error];

        alert(errorAlert)
  })

}

// CRIAR PIX

criarPix = (w) => {

  let pixTipoChave = this.state.pixTipoChave;

  let pixChave = this.state.pixChave;

  if(pixTipoChave/1 === 0){
    pixTipoChave = 'EMAIL'
  }
  if(pixTipoChave/1 === 1){
    pixTipoChave = 'CPF'
    pixChave = pixChave.replace(/[^0-9]/g, '');
  }
  if(pixTipoChave/1 === 2){
    pixTipoChave = 'TELEFONE'
    pixChave = pixChave.replace(/[^0-9]/g, '');
  
  }
  if(pixTipoChave/1 === 3){
    pixTipoChave = 'CNPJ'
    pixChave = pixChave.replace(/[^0-9]/g, '');
  }


  axios({
      method: 'post',
      url: 'https://api.transfeera.com/batch/'+this.state.id_lote+'/transfer', 
      headers: {
              "Authorization": 'Bearer '+this.state.token_transfeera,
              "User-Agent": "Santa Transmedia (giovanna@sant.at)",
              "Content-Type": "application/json",
              
      },
      data: {
              "value": this.state.pixValor,
              "payment_date": new Date().toISOString().split('T')[0],
              "idempotency_key": this.state.pixId,
              "pix_description":'Cache teste - ID '+this.state.pixId+' - Projeto'+this.state.pixProjeto,
              "destination_bank_account": {
                  "pix_key_type": pixTipoChave,
                  "pix_key": pixChave,
                  "email": this.state.pixEmail
                }
                
      }
    }).then((response) => {
          // handle success
          
          this.transferir()
         
    }).catch(function (error) {
          // handle error
          
          let errorAlert = [error];
          alert(errorAlert)
    })


}


transferir = () => {

  axios({
      method: 'post',
      url: 'https://api.transfeera.com/batch/'+this.state.id_lote+'/close', 
      headers: {
              "Authorization": 'Bearer '+this.state.token_transfeera,
              "User-Agent": "Santa Transmedia (giovanna@sant.at)",
              "Content-Type": "application/json",
              
      }
    }).then((response) => {

          // handle success
          
          this.salvarPixBD()
          
         
    }).catch(function (error) {
          // handle error
          alert(error);
    })
  
}


// SALVAR BD

salvarPixBD = () => {

  api.get('insert/pagaCacheTeste.php?id='+this.state.pixId+'&projeto_id='+this.state.id+'&usuario_id='+this.state.usuario_id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {
       
        this.getTestes()

        this.getLines()

        
       
    }})
}


// GET CACHE TESTES

getTestes = () =>{

  api.get('get/getTestes.php?id='+this.state.id+'&r='+Math.random(10000)).then(result => {
        
    if (result.status === 200) {

      this.setState({

        listTestes:result.data.testes,

      })
    }})  

}


// PAGAR TESTE PIX

pagarTestePix = (objId) => {


  this.toLoad()
  if(this.state.token_transfeera){
    
    this.endLoad()
    setTimeout(() => {
      this.criarLote()
    }, 100);
  }else{
  
    this.tokenPix()
  }

  this.setState({
    pixObjId:objId,
    pixId:this.state.listTestes[objId]['id'],
    pixNome:this.state.listTestes[objId]['nome'],
    pixEmail:this.state.listTestes[objId]['email'],
    pixValor:this.state.listTestes[objId]['valor'],
    pixChave:this.state.listTestes[objId]['pix'],
    pixTipoChave:this.state.listTestes[objId]['tipo'],
    pixProjeto:this.state.listTestes[objId]['nome_projeto'],
  })


}

// VER IMAGEM CACHE

verImagem = (img) => {
  window.open('https://treatments.run/landscape/img/upload/'+img)
}


// SETAR CNPJ RECEITA


setaCNPJBusca = (e) => {

  let cnpj = e.target.value.replace(/\D/g, "")
  this.setState({
    cnpjBusca:cnpj
  })

}

// BUSCAR CNPJ RECEITA

buscarCNPJReceita = () => {
  this.toLoad()
  let fornecedor = [];
  axios.get('https://publica.cnpj.ws/cnpj/'+this.state.cnpjBusca).then(result => {
        
  if (result.status === 200) {

        
    api.get('get/get.php?sheet=users&id='+this.state.cnpjBusca+'&col_id=cnpj&empresa_id='+this.state.empresa_id+'&r='+Math.random(10000)).then(result2 => {
        
      if (result2.status === 200) {

        
        if(result2.data.length===0){
        
        let resultCNPJ = result.data

        fornecedor = [{"id":"0","empresa_id":this.state.empresa_id,"permissao":"5","unidade_id":"0","foto":"","nome":resultCNPJ.razao_social,"email":resultCNPJ.estabelecimento.email,"senha":"","url":"","contrato":"0","overhead":"0","cargo":"5","bonus":"","tipo":"1","cpf":"","cnpj":resultCNPJ.estabelecimento.cnpj,"salario":"0","razao_social":resultCNPJ.razao_social,"inscricao_estadual":"","fone":'',"endereco":"","cidade":"","drt":"","aniversario":"","banco":"","agencia":"","conta_corrente":"","confirmado":"0","skill":"0","page":"","token":"","active":"0"}]
        
        this.setState({
          fornecedor:fornecedor,
          cnpjOpen2:true
        })

        this.endLoad()

        setTimeout(() => {
          
          this.novoCNPJ(resultCNPJ.estabelecimento.email)
        }, 10);

        
      }else{
        alert('CNPJ já cadastrado. Busque novamente.')
        this.setState({
          mostraCNPJ:false,
          mostraBuscaCNPJ:false,
          fornecedorNovo:0
        })
        this.endLoad()
      }

      }})


      
  }else{
    alert('CNPJ Inválido!')
  }})  

}

// CHANGE ACESSO

changeAcesso = (e) => {
 
  this.setState({
    tipoAcesso:e.target.checked
  })
}


// SWITCH EVENTOS

toSwitch = (o,o2) => {

  let live_action = this.state.live_action
  live_action[this.state.sub_nucleos[o]][o2].nf = 1-live_action[this.state.sub_nucleos[o]][o2].nf/1

  this.setState({
    live_action:live_action
  })

  api.get('update/update.php?empresa_id='+localStorage['empresa_id']+'&value='+live_action[this.state.sub_nucleos[o]][o2].nf+'&id='+live_action[this.state.sub_nucleos[o]][o2].id+'&sheet=cost&col=nf&r='+Math.random(10000)).then(result2 => {
        
    if (result2.status === 200) {
      this.calc_ini()
    }}
  ).catch(error=>{
    alert('Erro ao salvar!')
  })

 
}

// OFFICE

office = () => {
  window.open('/officesanta/'+this.state.budgets[0].id)
}


// CALENDAR

calendar = () => {
  window.open('/calendar/'+this.state.budgets[0].id)
}

// AJUSTAR FEE E IMPOSTOS

openAdjustEventos = () => {
  this.setState({
    ajustesEventos:true
  })
}

// UP FEE IMPOSTOS

upFeeImpostos = (e,w) =>{
  if(w===0){
    this.setState({
      fee:e.target.value
    })
  }
  if(w===1){
    this.setState({
      impostosBudget:e.target.value
    })
  }
}
// SAVE FEE IMPOSTOS

saveFeeImpostos = () =>{

  api.get('update/upFeeImpostos.php?projeto_id='+this.state.id+'&fee='+this.state.fee+'&impostos='+this.state.impostosBudget+'&r='+Math.random(10000)).then(result2 => {
    
    this.fechaDialog()
  })
}
// VOID

void = () => {

}




  render(){

  return (

    
    <ThemeProvider theme={theme}>
       {this.state.red}
       {this.state.permission > 2 ? <Redirect to='/login'/> : ''}
      
      <Meta title="Projetos"/>
      
      <ProgressBar perc={this.state.loading ? this.state.loadPerc : 100 } op={this.state.loading ? 1 : 0}/>
        
      <div className="conteudo">

      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>this.fechaMenu()}
                        >
                                
                                  <StyledMenuItem onClick={()=>this.openAcessos()}><IconButton><Group/></IconButton><ListItemText primary="Dar acesso a planilha" /></StyledMenuItem>
                                  <StyledMenuItem onClick={()=>this.listaEquipe()}><IconButton><AssignmentInd/></IconButton><ListItemText primary="Baixar lista de equipe" /></StyledMenuItem>
                                  <StyledMenuItem onClick={()=>this.openVerba()}><IconButton><Payment/></IconButton><ListItemText primary="Solicitar verba" /></StyledMenuItem>
                                  {this.state.eventos/1===0 ? <StyledMenuItem onClick={()=>this.openVerbaElenco()}><IconButton><StarBorder/></IconButton><ListItemText primary="Teste de elenco" /></StyledMenuItem>:''}
                                  

                                  {this.state.eventos/1===1 ? <StyledMenuItem onClick={()=>this.openAdjustEventos()}><IconButton><Tune/></IconButton><ListItemText primary="Impostos e Fee" /></StyledMenuItem>:''}
                                  {this.state.eventos/1===1 ? <StyledMenuItem onClick={()=>this.calendar()}><IconButton><CalendarToday/></IconButton><ListItemText primary="Cronograma" /></StyledMenuItem>:''}
                                  {this.state.eventos/1===1 ? <StyledMenuItem onClick={()=>this.office()}><IconButton><Description/></IconButton><ListItemText primary="Carta orçamento" /></StyledMenuItem>:''}
                                  

                                  <StyledMenuItem onClick={()=>this.xray()}><IconButton><InsertDriveFile/></IconButton><ListItemText primary="X-Ray" /></StyledMenuItem>
                                  <StyledMenuItem onClick={()=>this.shooting()}><IconButton color={this.state.shooting/1===0 ? "inherit" : "secondary"}  target="_blank"><Videocam/></IconButton><ListItemText primary="Em produção" /></StyledMenuItem> 
                                  
                                  {this.state.permission/1<2 ? 
                                    <StyledMenuItem onClick={()=>this.showlock()}><IconButton onClick={this.state.closed===0 ? ()=>this.showlock() : ()=>this.void()} color={this.state.closed===0 ? "inherit" : "secondary"} target="_blank">{this.state.closed===0 ? <LockOpen/> : <Lock/>}</IconButton>{this.state.closed===0 ?<ListItemText primary="Fechar projeto" /> : <ListItemText primary={this.formatDate2(this.state.closed_date)} /> }</StyledMenuItem>
                                    
                                  :''}
                                  
                                
      </Menu>

            
      <Sticky className="holdstick" stickyClassName="sticky2">

       
            <Grid container spacing={3}>
                  
                  <Grid item xs={8}><div className="titulo"><span className="color_1">{this.state.id+' '}</span>{this.state.cliente ? this.state.cliente+' '+this.state.nome : ''}</div></Grid>
                  
                  {this.state.block ? 

                    <Grid item xs={4} >
                
                      <Grid container justifyContent="space-between">
                      
                        <Tooltip title="Esconder Linhas" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(e)=>this.viewLines()} color={this.state.view===0 ? "inherit" : "secondary"} target="_blank">{this.state.view===0 ? <Visibility/> : <VisibilityOff/>}</IconButton></Tooltip>
                        <Tooltip title="Ver Comentários" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(e)=>this.viewComentario()} color={this.state.comments===0 ? "inherit" : "secondary"} target="_blank">{<Comment/>}</IconButton></Tooltip>
                        <Tooltip title="Ver coluna do meio" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.viewColMeio()} color={this.state.colMeio===0 ? "inherit" : "secondary"}  target="_blank"><ListAlt/></IconButton></Tooltip>
                        <Tooltip title="Ver performance" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.viewPerformance()} color={this.state.temperature===0 ? "inherit" : "secondary"}  target="_blank"><AnalyticsIcon/></IconButton></Tooltip>
                        <Tooltip title="Mais ações" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={(e)=>this.abreMenu(e)}  target="_blank"><MoreVert/></IconButton></Tooltip>
                      
                      </Grid>
                  
                    </Grid>

                   :
                      
                   ''}
                  
            </Grid>
       
          
          </Sticky>

          <div className="mt10 mb5" onClick={()=>this.viewTrack()}>
                <Grid container spacing={0} className={(this.state.valor_live_total-this.state.valor_live_real)+(this.state.valor_fornecedores_post-this.state.valor_fornecedores_post_real)+(this.state.valor_time_interno-this.state.valor_time_interno_real)+(this.state.valor_ancine-this.state.valor_ancine_real)+(this.state.valor_copias-this.state.valor_copias_real)+(this.state.valor_comissao-this.state.valor_comissao_real)+(this.state.valor_api-this.state.valor_api_real)-this.state.valor_custos_extras<0 ? "bgcolor_5 pd30 mt20 br5 mini-titulo" : "bgcolor_2 pd30 mt20 br5 mini-titulo"}>
                <Grid item xs={5} className=""><div className="pd5">Resultado Global</div></Grid>
                        
                        <Grid item xs={4} className=""><div className="pd5 color_0">{<CurrencyFormat value={(this.state.budgets ? this.state.budgets.reduce((a,b) => a = a + b.valor/1,0) : 0)-(this.state.impostosValor)-((this.state.valor_live_real)+(this.state.valor_fornecedores_post_real)+(this.state.valor_time_interno_real)+(this.state.valor_ancine_real)+(this.state.valor_copias_real)+(this.state.valor_comissao_real)+this.state.valor_custos_extras+this.state.valor_api_real)}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>

                        <Grid item xs={1} className=""><div className="pd5">Shift</div></Grid>
                       <Grid item xs={2} className=""><div className="tr pd5 color_0">{<CurrencyFormat value={(this.state.valor_live_total-this.state.valor_live_real)+(this.state.valor_fornecedores_post-this.state.valor_fornecedores_post_real)+(this.state.valor_time_interno-this.state.valor_time_interno_real)+(this.state.valor_ancine-this.state.valor_ancine_real)+(this.state.valor_copias-this.state.valor_copias_real)+(this.state.valor_comissao-this.state.valor_comissao_real)+(this.state.valor_api-this.state.valor_api_real)-this.state.valor_custos_extras}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                       
                </Grid>
          </div>
          {this.state.showTrack ? 
            <TrackRecord id={this.state.id} track={this.state.report} report_date={this.state.report_date}/>
          :
            ''
          }


          {this.state.block && this.state.eventos/1===0 ? 
            <div className="mt10 mb5">
                    <Grid container spacing={0} className={"bgcolor_8 mt20 br5"}>
                          <Grid item xs={5} className="micro-titulo"><div className="mt5 pd15">Budgets</div></Grid>
                          <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Valor Total</div></Grid>
                          <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">{this.state.empresa_id/1===4 ? 'Custos' : 'Live Action'}</div></Grid>
                          <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15">Fornecedores</div></Grid>
                          <Grid item xs={1} className="micro-titulo"><div className="mt5 pd15 tr">Actions</div></Grid>
                    </Grid>
            </div>
          :
            ''
          }


          {this.state.budgets && this.state.block && this.state.eventos/1===0 ? this.state.budgets.map((budgets,objectID)=> (
          <div key={objectID} className="mb1 line">
                  <Grid container spacing={0} className={"bgcolor_7 br5"}>
                      
                        <Grid item xs={5} onClick={(w)=>this.viewSheet(budgets.id)} className=""><div className="mt5 pd15"><span className="color_1">{budgets.id}</span> {this.state.nome+' - '+budgets.nome}</div></Grid>
                        <Grid item xs={2} onClick={(w)=>this.viewSheet(budgets.id)} className=""><div className="mt5 pd15">{<CurrencyFormat value={budgets.valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={2} onClick={(w)=>this.viewSheet(budgets.id)} className=""><div className="mt5 pd15">{<CurrencyFormat value={budgets.live_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={2} onClick={(w)=>this.viewSheet(budgets.id)} className=""><div className="mt5 pd15">{<CurrencyFormat value={budgets.freelas_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className="micro-titulo">

                        <Grid container justifyContent="flex-end">
                            <Tooltip title="Esconder Linhas" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.viewLinesBudget(budgets.id)} color={this.state.viewB.indexOf(budgets.id/1)>=0 ? "inherit" : "secondary"} target="_blank">{this.state.viewB.indexOf(budgets.id/1)<0 ? <Visibility/> : <VisibilityOff/>}</IconButton></Tooltip>
                            <Tooltip title="Fechar budget" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={budgets.closed/1===0 ? ()=>this.showlock(budgets.id) : ()=>this.void()} color={budgets.closed/1===0 ? "inherit" : "secondary"} target="_blank">{budgets.closed===0 ? <LockOpen/> : <Lock/>}</IconButton></Tooltip>
                            
                            </Grid>
                            
                        </Grid>
                  </Grid>

          </div>

        


          )) : ''}

          {this.state.block && this.state.eventos/1===0 ? 

            <div className="mt10 mb5">
                  <Grid container spacing={0} className={"bgcolor_8 br5"}>
                          <Grid item xs={5} className="micro-titulo"><div className="mt5 pd15">Total</div></Grid>
                          <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15"><CurrencyFormat value={this.state.budgets ? this.state.budgets.reduce((a,b) => a = a + b.valor/1,0) : 0}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                          <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15"><CurrencyFormat value={this.state.budgets ? this.state.budgets.reduce((a,b) => a = a + b.live_total/1,0) : 0}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                          <Grid item xs={2} className="micro-titulo"><div className="mt5 pd15"><CurrencyFormat value={this.state.budgets ? this.state.budgets.reduce((a,b) => a = a + b.freelas_total/1,0) : 0}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                  </Grid>
            </div>

          : 
          ''
          }


         

{this.state.blocosAtivos[0]['a_live_action']==='0' && this.state.block ? 
    <div>
    
        <div className="mt10 mb5">
                  <Grid container spacing={0} className={"bgcolor_1 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">Live Action</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15 color_9">Orçado</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 tr pd15">{<CurrencyFormat value={this.state.valor_live_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                        <Grid item xs={3} className=""><div className="mt5 pd15 color_9">{this.state.colMeio===0 ? 'Realizado' : 'Previsto'}</div></Grid>
                        {this.state.colMeio===0 ? 
                          <Grid item xs={1} className=""><div className="mt5 pd15">{<CurrencyFormat value={this.state.valor_live_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        :
                          <Grid item xs={1} className=""><div className="mt5 pd15">{<CurrencyFormat value={this.state.valor_live_previsto}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                        }
                  </Grid>
          </div>

          {this.state.sub_nucleos_display.map((sub,objectID)=> (
            <div key={objectID}>
          <Grid container spacing={0} onClick={(w)=>this.abreNucleo(objectID)} className={"bgcolor_1 br5 mb1 line"}>
                      
                <Grid item xs={5} className="">
                      <div className="mt5 pd10 l f">
                      {this.state.eventos/1===1 ? <div className="mr30">NF / ND</div>
                      :''}
                        <div>{sub}</div>
                        </div>
                </Grid>
                
                <Grid item xs={4} className="">
                      <div className="mt5 pd15 l"><CurrencyFormat value={this.state.sub_nucleos_valor[objectID]}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                </Grid>
                {this.state.colMeio===0 ? 
                <Grid item xs={1} className="">
                      <div className="mt5 pd15 l"><CurrencyFormat value={this.state.sub_nucleos_real[objectID]}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                </Grid>
                :
                <Grid item xs={1} className="">
                      <div className="mt5 pd15 l"><CurrencyFormat value={this.state.sub_nucleos_previsto[objectID]}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                </Grid>
              }
          </Grid>

          
          
            {this.state.ativos[objectID]===1 ? this.state.live_action[this.state.sub_nucleos[objectID]].map((lines,objectID2)=>(
            
          <Grid key={objectID2} container spacing={0} className={this.state.temperature ? lines.o_total/1 >= lines.r_total/1 ? "cor_perf_1 line-budget mb1 br5":"cor_perf_2 line-budget mb1 br5" : "bgcolor_7 line-budget mb1 br5"} >
                      
                        <Grid item xs={2} className="">
                          
                        {this.state.eventos/1===1 ? 
                            
                            <Grid  container>
                              <Grid item xs={3}>
                              <Switch checked={lines.nf/1} onChange={()=>this.toSwitch(objectID,objectID2)} />
                              </Grid>
                               <Grid item xs={9} className="bgcolor_10" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_nome',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_nome' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(10,e,lines.id,10,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,0,lines.id,0)} placeholder={lines.nome} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.nome}</div>}</Grid>
                            </Grid>
                          
                          : 
                            <div className="ff-ro eli">{lines.nome}</div>
                          }

                          
                        </Grid>
                      
                        {this.state.comments===0 ? 
                        <Grid item xs={4}>
                          {this.state.eventos===0 ? 
                            <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                              <Grid item xs={3} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>
                            :
                            
                            <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_10 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_o_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_o_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(8,e,lines.id,8,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,0,lines.id,0)} placeholder={lines.o_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.o_qt}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_10 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_o_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_o_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(9,e,lines.id,8,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,1,lines.id,0)} placeholder={lines.o_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.o_diaria}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_10" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_o_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_o_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(10,e,lines.id,8,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,2,lines.id,0)} placeholder={lines.o_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}><CurrencyFormat value={lines.o_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>
                            
                            
                            }
                        </Grid>
                         : 

                         <Grid item xs={4} className="bgcolor_10" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_com',lines.empresa_id)}>{this.state.edit === lines.id+'_com' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(3,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} defaultValue={lines.comentario.replace('"','')} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.comentario.replace('"','')}</div>}</Grid>
                        
                        }

                      


                        {this.state.colMeio===0 ? 
                          <Grid item xs={4}>
                            <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,0,lines.id,0)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.r_qt}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,1,lines.id,0)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.r_diaria}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_7" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,0,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,2,lines.id,0)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                              <Grid item xs={3} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>
                          </Grid>
                          :
                          <Grid item xs={4}>
                            <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_11 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_pqt',lines.empresa_id) : ()=>this.void()}>{this.state.edit === lines.id+'_pqt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(4,e,lines.id,6,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,0,lines.id,6)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.p_qt}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_11 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_pdiaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_pdiaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(5,e,lines.id,6,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,1,lines.id,6)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.p_diaria}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_11" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_pvalor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_pvalor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(6,e,lines.id,6,this.state.sub_nucleos[objectID],objectID2)} onKeyDown={(e)=>this.handleKeyDown(e,objectID,objectID2,2,lines.id,6)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}><CurrencyFormat value={lines.p_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                              <Grid item xs={3} className="bgcolor_11"><div className="ff-ro">{<CurrencyFormat value={lines.p_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>
                          </Grid>
                        }


                        <Grid item xs={2} className="icons-line bgcolor_10">
                          
                          {!this.state.temperature ? 
                            <Grid container spacing={0} justifyContent={this.state.budgetClosed.indexOf(lines.budget_id/1) >= 0 && lines.status>0 ? "flex-end" : "space-between"}>
                            
                              {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title={lines.locked/1===0 ? "Bloquear linha" : 'Desbloquear linha'} placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={(w)=>this.travarLinha(lines.id,1-lines.locked,0,objectID,objectID2)} target="_blank">{lines.locked/1===0 ? <LockOpen/> : <Lock color={lines.locked/1===1 ? "secondary" : 'inherit'}/>}</IconButton></Tooltip> : ''}
                              {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Duplicar linha" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={(w)=>this.duplicarLinhas(lines.id)} target="_blank"><AddToPhotos/></IconButton></Tooltip> : ''}
                              {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Dados para pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={lines.pj_id>0 ? "secondary" : 'inherit'} onClick={(w,pj)=>this.abreCNPJ(lines.id,lines.pj_id,0,objectID,objectID2)} target="_blank"><Receipt/></IconButton></Tooltip> : ''}
                              {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Data de pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton color={lines['dt_prevista']  ? "secondary" : 'inherit'} size="small" onClick={(w,d)=>this.abreData(0,lines.id,lines.dt_prevista ,objectID,objectID2)} target="_blank"><CalendarToday/></IconButton></Tooltip> : ''}
                             
                              <div>
                                  {(this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 || lines.status>0) && lines.r_valor>0 ?<Tooltip title={"Enviar PO"} placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={this.state.colorStatus[lines.status]} onClick={lines.status==='0' ? (w)=>this.enviaPO(lines.id,0,0,objectID,objectID2) : ()=>this.mostraPoInfo(lines.id)}><Check/></IconButton></Tooltip> : <IconButton className="opacity-30" size="small" color={this.state.colorStatus[lines.status]}><Check/></IconButton>}
                              </div>
                             
                          </Grid>

                          :
                          
                          <Grid container spacing={0} justifyContent="space-between">
                            <div className={lines.o_total/1>=lines.r_total/1 ? "b mt10 color_2" :  "b mt10 color_5"}>{<CurrencyFormat value={lines.o_total/1-lines.r_total/1}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div>
                          </Grid>
                            }

                        </Grid>
                    
                     
         </Grid>
            )) : ''}
         </div>


))}
</div>
: ''}

  {this.state.blocosAtivos[0]['a_time_interno']==='0' && this.state.block ? 
          <div className="mt10 mb5">
                <Grid container spacing={0} className={"bgcolor_2 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">Time Interno</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.valor_time_interno}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.valor_time_interno_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                  </Grid>


          {this.state.time_interno ? this.state.time_interno['unico'].map((lines,objectID3)=>(
            
            <Grid key={objectID3} container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="">
                          <div className="ff-ro l">{lines.nome}</div></Grid>
                        
  
                        <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>

                        <Grid item xs={1} className="bgcolor_7 tc"><div className="ff-ro">{lines.r_qt}</div></Grid>
                        <Grid item xs={1} className="bgcolor_7 tc"><div className="ff-ro">{lines.r_diaria}</div></Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro"><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div></Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10">
                              <Grid container spacing={0} justifyContent="space-between">
                              
                            
                          </Grid>
                          </Grid>
                      
                       
           </Grid>
              )) : ''}


          </div>
: ''}
        {this.state.blocosAtivos[0]['a_fornecedores_post']==='0' && this.state.block ? 
          <div className="mt10 mb5">
                <Grid container spacing={0} className={"bgcolor_3 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className="">
                          {this.state.eventos/1===0 ? 
                          <div className="mt5 mt20">Fornecedores</div>
                          :
                          <div className="mt5 mt20">Equipe interna</div>
                          }
                          </Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.valor_fornecedores_post}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.valor_fornecedores_post_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                  </Grid>


                  {this.state.fornecedores_post ? this.state.fornecedores_post['unico'].map((lines,objectID4)=>(
            
            <Grid key={objectID4} container spacing={0} className={this.state.temperature ? lines.o_total/1 >= lines.r_total/1 ? "cor_perf_1 line-budget mb1 br5":"cor_perf_2 line-budget mb1 br5" : "bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_nome',lines.empresa_id)}>
                         
                          {this.state.edit === lines.id+'_nome' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(7,e,lines.id,2,'unico',objectID4)} defaultValue={lines.nome} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.nome}</div>}
                        
                          </Grid>
                        
  
                          {this.state.comments===0 ? 
                        <Grid item xs={4}>
                          {this.state.eventos/1===0 ?

                            <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                              <Grid item xs={3} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>

                            :

                            <Grid  container spacing={0}>
                              <Grid item xs={3} className="bgcolor_10 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_f_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_f_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(8,e,lines.id,9,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,0,lines.id,2)} placeholder={lines.o_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.o_qt}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_10 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_f_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_f_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(9,e,lines.id,9,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,1,lines.id,2)} placeholder={lines.o_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.o_diaria}</div>}</Grid>
                              <Grid item xs={3} className="bgcolor_10" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_f_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_f_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(10,e,lines.id,9,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,2,lines.id,2)} placeholder={lines.o_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}><CurrencyFormat value={lines.o_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                              <Grid item xs={3} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                            </Grid>
                            
                            }

                        </Grid>
                         : 

                         <Grid item xs={4} className="bgcolor_10" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_com',lines.empresa_id)}>{this.state.edit === lines.id+'_com' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(3,e,lines.id,2,'unico',objectID4)} defaultValue={lines.comentario} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.comentario.replace('"','')}</div>}</Grid>
                        
                        }

                        <Grid item xs={1} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_qt',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,2,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,0,lines.id,2)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.r_qt}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_diaria',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,2,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,1,lines.id,2)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}>{lines.r_diaria}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_valor',lines.empresa_id):()=>this.void()}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,2,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,2,lines.id,2)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className={lines.status/1===0 ? "ff" : "ff-ro"}><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10">
                              
                              {lines.empresa_id===this.state.empresa_id && this.state.eventos/1===0 ? 
                              <Grid container spacing={0} justifyContent={this.state.budgetClosed.indexOf(lines.budget_id/1) >= 0 && lines.status>0 ? "flex-end" : "space-between"}>
                                {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Duplicar linha" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={(w)=>this.duplicarLinhas(lines.id)} target="_blank"><AddToPhotos/></IconButton></Tooltip> : ''}
                                {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Dados para pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={lines.pj_id>0 ? "secondary" : 'inherit'} onClick={(w,pj)=>this.abreCNPJ(lines.id,lines.pj_id,1,0,objectID4)} target="_blank"><Receipt/></IconButton></Tooltip> : ''}
                                {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Data de pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton color={lines['dt_prevista']  ? "secondary" : 'inherit'} size="small" onClick={(w)=>this.abreData(1,lines.id,lines['dt_prevista'] ,0,objectID4)} target="_blank"><CalendarToday/></IconButton></Tooltip> : ''}
                                {(this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 || lines.status>0) && lines.r_valor>0 ? <Tooltip title="Enviar PO" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={this.state.colorStatus[lines.status]} onClick={lines.status==='0' ? (w)=>this.enviaPO(lines.id,0,1,0,objectID4) : ()=>this.mostraPoInfo(lines.id)}><Check/></IconButton></Tooltip> : <IconButton className="opacity-30" size="small" color={this.state.colorStatus[lines.status]}><Check/></IconButton>}
                              </Grid>
                              : ''}
                          
                          
                          </Grid>
                      
                       
           </Grid>
              )) : ''}
          </div>
        : ''}

        {this.state.blocosAtivos[0]['a_copias']==='0' && this.state.block ? 
          <div className="mt10 mb5">
                <Grid container spacing={0} className={"bgcolor_4 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">Cópias</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.valor_copias}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.valor_copias_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                  </Grid>

          {this.state.copias ? this.state.copias['unico'].map((lines,objectID4)=>(
            
            <Grid key={objectID4} container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="">
                          <div className="ff-ro l">{lines.nome}</div></Grid>
                        
  
                          <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>

                        <Grid item xs={1} className="bgcolor_7 tc" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_qt',lines.empresa_id)}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,3,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,0,lines.id,3)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_qt}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7 tc" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_diaria',lines.empresa_id)}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,3,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,1,lines.id,3)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_diaria}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_valor',lines.empresa_id)}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,3,'unico',objectID4)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID4,2,lines.id,3)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff"><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10">
                              <Grid container spacing={0} justifyContent="space-between">
                              
                             
                          </Grid>
                          </Grid>
                      
                       
           </Grid>
              )) : ''}
          </div>
          : ''}

          {this.state.blocosAtivos[0]['a_ancine']==='0' && this.state.block ? 

          <div className="mt10 mb5">
                <Grid container spacing={0} className={"bgcolor_5 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">Condecine</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.valor_ancine}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                        <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.valor_ancine_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        
                  </Grid>

          {this.state.ancine ? this.state.ancine['unico'].map((lines,objectID5)=>(
            
            <Grid key={objectID5} container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="">
                          <div className="ff-ro l">{lines.nome}</div></Grid>
                        
  
                          <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>

                        <Grid item xs={1} className="bgcolor_7 tc" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_qt',lines.empresa_id)}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,4,'unico',objectID5)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID5,0,lines.id,4)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_qt}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7 tc" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_diaria',lines.empresa_id)}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,4,'unico',objectID5)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID5,1,lines.id,4)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_diaria}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_valor',lines.empresa_id)}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,4,'unico',objectID5)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID5,2,lines.id,4)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff"><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10">
                              <Grid container spacing={0} justifyContent="flex-end">
                              
                              <Grid container spacing={0} justifyContent={this.state.budgetClosed.indexOf(lines.budget_id/1) >= 0 && lines.status>0 ? "flex-end" : "space-between"}>
                            
                                  {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title={lines.locked/1===0 ? "Bloquear linha" : 'Desbloquear linha'} placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={(w)=>this.travarLinha(lines.id,1-lines.locked,5,0,objectID5)} target="_blank">{lines.locked/1===0 ? <LockOpen/> : <Lock color={lines.locked/1===1 ? "secondary" : 'inherit'}/>}</IconButton></Tooltip> : ''}
                                  {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Duplicar linha" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" onClick={(w)=>this.duplicarLinhas(lines.id)} target="_blank"><AddToPhotos/></IconButton></Tooltip> : ''}
                                  {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Dados para pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={lines.pj_id>0 ? "secondary" : 'inherit'} onClick={(w,pj)=>this.abreCNPJ(lines.id,lines.pj_id,5,0,objectID5)} target="_blank"><Receipt/></IconButton></Tooltip> : ''}
                                  {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Data de pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton color={lines['dt_prevista']  ? "secondary" : 'inherit'} size="small" onClick={(w,d)=>this.abreData(5,lines.id,lines.dt_prevista,0,objectID5)} target="_blank"><CalendarToday/></IconButton></Tooltip> : ''}
                                  {(this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 || lines.status>0) && lines.r_valor>9 ? <Tooltip title={"Enviar PO"} placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={this.state.colorStatus[lines.status]} onClick={lines.status==='0' ? (w)=>this.enviaPO(lines.id,0,5,0,objectID5) : ()=>this.mostraPoInfo(lines.id)}><Check/></IconButton></Tooltip> : <IconButton className="opacity-30" size="small" color={this.state.colorStatus[lines.status]}><Check/></IconButton>}
                        
                        </Grid>
                          
                          </Grid>
                          </Grid>
                      
                       
           </Grid>
              )) : ''}

          </div>

          : ''}

          {(this.state.blocosAtivos[0]['a_api']/1>0 || this.state.empresa_id/1===4) && this.state.block ?
          <div className="mt10 mb5">
                <Grid container spacing={0} className={"bgcolor_8 pd30 mt20 br5 mini-titulo"}>
                        <Grid item xs={2} className=""><div className="mt5 mt20">LANDSCAPE/API</div></Grid>
                        <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                        {this.state.valor_api ? <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.empresa_id/1===4 ? this.state.valor_api/1+this.state.liquor_api/1*0.4 : this.state.valor_api}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid> : ''}
                        <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                        {this.state.valor_api ? <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.empresa_id/1===4 ? this.state.valor_api_real/1+this.state.liquor_api/1*0.4 : this.state.valor_api_real} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid> : ''}
                        
                  </Grid>

          {this.state.api_list ? this.state.api_list['unico'].map((lines,objectID6)=>(
            
            <Grid key={objectID6} container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="">
                          <div className="ff-ro l">{lines.nome}</div></Grid>
                        
  
                          <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                        <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>

                        <Grid item xs={1} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_qt',lines.empresa_id,1):()=>this.void()}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,5,'unico',objectID6)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID6,0,lines.id,5)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_qt}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7 tc" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_diaria',lines.empresa_id,1):()=>this.void()}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,5,'unico',objectID6)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID6,1,lines.id,5)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_diaria}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7" onClick={lines.status/1===0 ? (w)=>this.toEdit(lines.budget_id,lines.id+'_valor',lines.empresa_id,1):()=>this.void()}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,5,'unico',objectID6)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID6,2,lines.id,5)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff"><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10">
                              <Grid container spacing={0} justifyContent={this.state.budgetClosed.indexOf(lines.budget_id/1) >= 0 && lines.status>0 ? "flex-end" : "space-between"}>
                              
                              {this.state.empresa_id/1!==4 ? <Tooltip title="Data de pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton color={lines['dt_prevista']  ? "secondary" : 'inherit'} size="small" onClick={(w)=>this.abreData(2,lines.id,lines.dt_prevista ,0,objectID6)} target="_blank"><CalendarToday/></IconButton></Tooltip> : ''}
                              {this.state.empresa_id/1!==4 ? <Tooltip title="Enviar PO" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={this.state.colorStatus[lines.status]} onClick={lines.status==='0' ? (w,api)=>this.enviaPO(lines.id,1,2,0,objectID6) : ()=>this.mostraPoInfo(lines.id)}><Check/></IconButton></Tooltip> : ''}
                          
                          </Grid>
                          </Grid>
                      
                       
           </Grid>
              )) : ''}

              {this.state.liquor_api/1>0 ? 
                  <Grid container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                        
                        <Grid item xs={2} className="">
                        <div className="ff-ro l">LIQUOR</div></Grid>
                      

                        <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{1}</div></Grid>
                      <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{0.4}</div></Grid>
                      <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={this.state.liquor_api/1}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                      <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={this.state.liquor_api/1*0.4}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>

                      <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{1}</div></Grid>
                      <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{0.4}</div></Grid>
                      <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={this.state.liquor_api/1}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                      <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={this.state.liquor_api/1*0.4}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>



                        <Grid item xs={2} className="icons-line bgcolor_10">
                            <Grid container spacing={0} justifyContent="space-between">
                            
                           
                        
                        </Grid>
                        </Grid>
                    
                     
                </Grid>
              :''}

              
          </div>
          : ''}

          {this.state.block ? 
            <div className="mini-titulo mb20 mt20">Outros custos do projeto</div>
          :''}

          {this.state.block ? 
            <ListaProjetos hide_receitas={true} hide_impostos={true} abrirDataGrupo={()=>this.void()} abrirStatusGrupo={()=>this.void()} abreJanela={(w)=>this.void(w)} impostos={this.state.cashflowReportProjetoImpostos ? this.state.cashflowReportProjetoImpostos : ''} entradas={this.state.cashflowReportProjetoEntradas ? this.state.cashflowReportProjetoEntradas : ''} out={0} infos={this.state.cashflowReportProjeto ? this.state.cashflowReportProjeto : ''}/>
          :''}
          <div className="mt10 mb5">
                {this.state.block && this.state.eventos/1===0 ? 
                  
                    <Grid container spacing={0} className={"bgcolor_8 pd30 mt20 br5 mini-titulo"}>
                          <Grid item xs={2} className=""><div className="mt5 mt20">Cachês internos</div></Grid>
                          <Grid item xs={2} className=""><div className="mt5 pd15">Orçado</div></Grid>
                          <Grid item xs={2} className=""><div className="mt5 tr pd15 color_0">{<CurrencyFormat value={this.state.valor_comissao}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                          <Grid item xs={3} className=""><div className="mt5 pd15">Realizado</div></Grid>
                          <Grid item xs={1} className=""><div className="mt5 pd15 color_0">{<CurrencyFormat value={this.state.valor_comissao_real}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                          
                    </Grid>

                  :
                  ''}


                  {this.state.comissao && this.state.block ? this.state.comissao['unico'].map((lines,objectID7)=>(
            
            <Grid key={objectID7} container spacing={0} className={"bgcolor_7 line-budget mb1 br5"}>
                        
                          <Grid item xs={2} className="">
                          <div className="ff-ro l eli"><span className="color_1">{lines.budget_id}</span> {' '+lines.nome + ' | ' + lines.nome_pj}</div></Grid>
                        
                          
                          <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_qt}</div></Grid>
                          <Grid item xs={1} className="bgcolor_10 tc"><div className="ff-ro">{lines.o_diaria}</div></Grid>
                          <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                          <Grid item xs={1} className="bgcolor_10"><div className="ff-ro">{<CurrencyFormat value={lines.o_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
                    
                          <Grid item xs={1} className="bgcolor_7 tc" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_qt',lines.empresa_id)}>{this.state.edit === lines.id+'_qt' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(0,e,lines.id,7,'unico',objectID7)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID7,0,lines.id,7)} placeholder={lines.r_qt} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_qt}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7 tc" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_diaria',lines.empresa_id)}>{this.state.edit === lines.id+'_diaria' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(1,e,lines.id,7,'unico',objectID7)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID7,1,lines.id,7)} placeholder={lines.r_diaria} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff">{lines.r_diaria}</div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7" onClick={(w)=>this.toEdit(lines.budget_id,lines.id+'_valor',lines.empresa_id)}>{this.state.edit === lines.id+'_valor' ? <TextField autoFocus={true} color="secondary" variant="outlined" fullWidth={true} onBlur={(e)=>this.fieldBlur(2,e,lines.id,7,'unico',objectID7)} onKeyDown={(e)=>this.handleKeyDown(e,'unico',objectID7,2,lines.id,7)} placeholder={lines.r_valor} InputProps={{style: { height:0,padding: '18px 0px'}, }}/> : <div className="ff"><CurrencyFormat value={lines.r_valor} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>}</Grid>
                        <Grid item xs={1} className="bgcolor_7"><div className="ff-ro">{<CurrencyFormat value={lines.r_total}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /> }</div></Grid>
  
  
  
                          <Grid item xs={2} className="icons-line bgcolor_10">
                              <Grid container spacing={0} justifyContent={this.state.budgetClosed.indexOf(lines.budget_id/1) >= 0 && lines.status>0 ? "flex-end" : "space-between"}>
                              
                              {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ?  <Tooltip title={lines.nome_pj} placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={lines.pj_id>0 ? "secondary" : 'inherit'} onClick={(w,pj)=>this.abreCNPJ(lines.id,lines.pj_id,1,0,objectID7)}><Receipt/></IconButton></Tooltip>:''}
                              {this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 ? <Tooltip title="Data de pagamento" placement="top" arrow TransitionComponent={Zoom}><IconButton color={lines['dt_prevista']  ? "secondary" : 'inherit'} size="small" onClick={(w)=>this.abreData(3,lines.id,lines.dt_prevista ,0,objectID7)}><CalendarToday/></IconButton></Tooltip>:''}
                              {(this.state.budgetClosed.indexOf(lines.budget_id/1) < 0 || lines.status>0) && lines.r_valor>0 ? <Tooltip title="Enviar PO" placement="top" arrow TransitionComponent={Zoom}><IconButton size="small" color={this.state.colorStatus[lines.status]} onClick={lines.status==='0' ? (w)=>this.enviaPO(lines.id,0,3,0,objectID7) : ()=>this.mostraPoInfo(lines.id)}><Check/></IconButton></Tooltip> : <IconButton className="opacity-30" size="small" color={this.state.colorStatus[lines.status]}><Check/></IconButton>}
                          
                          </Grid>
                          </Grid>
                      
                       
           </Grid>


              )) : ''}
                </div>

                
              <Dialog
                open={this.state.cnpjOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>{"Dados do fornecedor"}</DialogTitle>
                <DialogContent>
                {this.state.closed===0 && !this.state.mostraBuscaCNPJ ?
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                  <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="pjs" label="CNPJ, Nome ou E-mail" change={(e)=>this.mostraDadosCNPJ(e)} blur={(e)=>this.novoCNPJ(e)}/>
                </Grid>
               
                  <Grid item xs={1} className="tr">
                  
                    <IconButton size="large" onClick={()=>this.novoCNPJ('Novo')}><Add fontSize="large"/></IconButton>
                  </Grid>

                  </Grid>
                  : ''}

                  {this.state.mostraBuscaCNPJ ? 
                  
                  <div className="mt20">
                    <Grid container spacing={1}>
                      <Grid item xs={11}>
                      <TextField fullWidth={true} label="Digite o CNPJ para buscar" onBlur={(e)=>this.setaCNPJBusca(e)}/>
                      </Grid>
                
                    <Grid item xs={1} className="tr">
                    
                      <IconButton size="large" onClick={()=>this.buscarCNPJReceita()}><Search fontSize="large"/></IconButton>
                    </Grid>

                    </Grid>
                  </div>
                  :''}


                {this.state.mostraCNPJ && !this.state.mostraBuscaCNPJ ? 
                <div className="mt20">

                  {this.state.fornecedor.map((fornecedor,objectId) => (

                    
                <Grid key={objectId+this.state.update} container spacing={1}>
                  {fornecedor.id>0 ? '' : <div className="wp100 pd10 mt20 mb20 mini-titulo">Cadastrar Novo fornecedor</div>}
                <Grid item xs={4}>
                  <TextField autoFocus={true} color="secondary" autoComplete="off" variant="outlined" label="Nome" fullWidth={true}  defaultValue={fornecedor.nome} placeholder={"Nome"} onBlur={(e)=>this.dadosFornecedor(e,'nome')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Razão Social" fullWidth={true}  defaultValue={fornecedor.razao_social} placeholder="Razão Social" onBlur={(e)=>this.dadosFornecedor(e,'razao_social')}/>
                </Grid>

                <Grid item xs={4}>

                <InputMask
                      mask="99.999.999/9999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cnpj} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cnpj')}
                      >
                      {() =>   <TextField variant="outlined" label="CNPJ" kind="cnpj" fullWidth={true}  placeholder={"CNPJ"} />}
                  </InputMask>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="RG" fullWidth={true}  defaultValue={fornecedor.rg} placeholder="RG" onBlur={(e)=>this.dadosFornecedor(e,'rg')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Data de Nascimento" fullWidth={true}  defaultValue={fornecedor.data_nascimento} placeholder="Data de Nascimento" onBlur={(e)=>this.dadosFornecedor(e,'data_nascimento')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="PIX" fullWidth={true}  defaultValue={fornecedor.pix} placeholder="PIX" onBlur={(e)=>this.dadosFornecedor(e,'pix')}/>
                </Grid>

               
                
             
                <Grid item xs={4}>
                  <TextField variant="outlined" label="E-mail" fullWidth={true}  defaultValue={fornecedor.email} placeholder={"E-Mail"} onBlur={(e)=>this.dadosFornecedor(e,'email')}/>
                </Grid>

                <Grid item xs={4}>
                <InputMask
                      mask="999.999.999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cpf} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cpf')}
                      >
                      {() =>   <TextField variant="outlined" label="CPF" fullWidth={true}  placeholder={"CPF"} />}
                  </InputMask>
                  
                </Grid>
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Celular" fullWidth={true}  defaultValue={fornecedor.fone} placeholder="Celular" onBlur={(e)=>this.dadosFornecedor(e,'fone')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Endereço" fullWidth={true}  placeholder="Endereço" onBlur={(e)=>this.dadosFornecedor(e,'endereco')}/>
                </Grid>

                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Cidade" fullWidth={true}  defaultValue={fornecedor.cidade} placeholder="Cidade" onBlur={(e)=>this.dadosFornecedor(e,'cidade')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="DRT" fullWidth={true}  defaultValue={fornecedor.drt} placeholder="DRT" onBlur={(e)=>this.dadosFornecedor(e,'drt')}/>
                </Grid>

                
                

                


                </Grid>
                ))}
                </div>
                : ''}
                
                  {this.state.fornecedorNovo === 1 && !this.state.mostraBuscaCNPJ ? 
                <Grid container>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_2 mt20" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_1 mt20 mb20" onClick={()=>this.cadastraFornecedor()} color="secondary">
                    Cadastrar
                  </div>
                  </Grid>
                </Grid>

                :''}



                {this.state.fornecedorNovo === 2 ? 
                <Grid container>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_2 mt20" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_1 mt20 mb20" onClick={()=>this.updateFornecedor()} color="secondary">
                    Atualizar dados
                  </div>
                  </Grid>
                </Grid>

                :''}


                
               
                </DialogContent>
                
              </Dialog>

              <Dialog
                open={this.state.sendOpen}
                onClose={()=>this.fechaDialog()}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>{"Enviar PO"}</DialogTitle>
                <DialogContent>
                    
                    <div className="b micro-titulo mb10"><CurrencyFormat value={this.state.valorSend}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></div>
                    <div className="mb30">{this.state.fornecedorSend}</div>
                    
                    <MyDatePicker onChange={(e)=>this.changeData(e)} view={["year", "month","date"]} format="dd/MM/yyyy" label="Data combinada" startDate={this.state.dataAberta}/>
                    <div className="mb20"></div>
                   
                      <div>
                      <Switch checked={this.state.poInterna} onChange={(e) => this.swPOInterna(e)} name="PO Interna" id='0' /> PO sem e-mail
                      {this.state.poInternaN ? <div className="mt10"><MySelect change={(e)=>this.setEmpresa(e)} label="Empresa a ser paga" list={this.state.listaEmpresas}/></div>: ''}
                      </div>
                    
                    <div className="mb20 h1 bgcolor_5"></div>
                </DialogContent>
               
                <DialogActions>
                
                  <div className="bt w20 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                 
                  {this.state.loading ? 
                  <div className="bt w20 bgcolor_6" color="secondary">
                    Enviando..
                  </div>: <div className="bt w20 bgcolor_1" onClick={()=>this.enviaPOGo()} color="secondary">
                    Enviar
                  </div>
                  }
                  
                </DialogActions>
                
              </Dialog>



              <Dialog
                open={this.state.ajustesEventos}
                onClose={()=>this.fechaDialog()}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>{"Impostos e Fee"}</DialogTitle>
                <DialogContent>
                    
                    <Grid container >
                      <Grid xs={6}>
                        <TextField variant="outlined" label="Fee %" onBlur={(e)=>this.upFeeImpostos(e,0)} fullWidth={true}  placeholder={"Fee"} defaultValue={this.state.fee}
                        
                        InputProps={{
                          
                          style: { 
                            height:0,
                            padding: '25px 0px',color:'white',placeholder:'grey'},  
                    
                            
                        }}
                        />
                      </Grid>

                      <Grid xs={6}>
                        <TextField variant="outlined" label="Impostos %" onBlur={(e)=>this.upFeeImpostos(e,1)} fullWidth={true}  placeholder={"Impostos"} defaultValue={this.state.impostosBudget}
                        
                        InputProps={{
                          
                          style: { 
                            height:0,
                            padding: '25px 0px',color:'white',placeholder:'grey'},  
                    
                            
                        }}
                        />
                      </Grid>
                      
                      </Grid>
                    
                    <div className="mb20 h1 bgcolor_5"></div>
                </DialogContent>
               
                <DialogActions>
                
                  <div className="bt w20 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                 
                  {this.state.loading ? 
                  <div className="bt w20 bgcolor_6" color="secondary">
                    Enviando..
                  </div>: <div className="bt w20 bgcolor_1" onClick={()=>this.saveFeeImpostos()} color="secondary">
                    Enviar
                  </div>
                  }
                  
                </DialogActions>
                
              </Dialog>

              
              <Dialog
                open={this.state.showListLock}
                onClose={()=>this.fechaDialog()}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>{"Fechar projeto"}</DialogTitle>
                <DialogContent>
                   
                   {this.state.crosscompany/1===1 ? 
                    <div className="micro-titulo mb20">
                    *** Lembre de conferir se já foi feita a transferência crosscompany. ***
                  </div>
                   :
                   ''
                   }
                    <div className="micro-titulo mb20">
                      Existem pagamentos sem PO enviadas:
                    </div>
                    {this.state.linhasSemPo.map((linhasSemPo,objectID) =>
                    ( 
                    
                      <div key={objectID} className="mb5 wp100">

                        <Grid container>
                            <Grid item xs={6}>{linhasSemPo.nome}</Grid>
                            <Grid item xs={6}><CurrencyFormat value={linhasSemPo.r_valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></Grid>
                        </Grid>

                       </div>

                    ))}

                    <div className="micro-titulo mb20 mt10">
                      Existem POs sem NF recebidas:
                    </div>
                    {this.state.linhasSemNF.map((linhasSemNF,objectID) =>
                    ( 
                    
                      <div key={objectID} className="mb5 wp100">

                        <Grid container>
                        <Grid item xs={2}>{linhasSemNF.id}</Grid>
                            <Grid item xs={6}>{linhasSemNF.nome}</Grid>
                            <Grid item xs={4}><CurrencyFormat value={linhasSemNF.valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} /></Grid>
                        </Grid>

                       </div>

                    ))}
                   
                </DialogContent>

               
                <DialogActions>
                
                  <div className="bt w20 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                 
                  {this.state.loading ? 
                  <div className="bt w20 bgcolor_6" color="secondary">
                    Fechando..
                  </div>: <div className="bt w20 bgcolor_1" onClick={this.state.closeBudget ? ()=>this.lockBudget(this.state.closeBudget) : ()=>this.lock()} color="secondary">
                    Continuar
                  </div>
                  }
                  
                </DialogActions>
                
              </Dialog>


              <Dialog
                open={this.state.abreInfoPo}
                onClose={()=>this.fechaDialog()}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>{"Resumo da PO"}</DialogTitle>
                <DialogContent>
                    
                
                      <div className="mb5 wp100">
                    {this.state.infoPo ? this.state.infoPo.map((infoPo,objectID) => (
                        <Grid container spacing={3} key={objectID}>
                            <Grid item xs={6}>Número PO:</Grid>
                            <Grid item xs={6}>{infoPo.id}</Grid>
                            <Grid item xs={6}>Razão Social:</Grid>
                            <Grid item xs={6}>{infoPo.pj_id}</Grid>
                            <Grid item xs={6}>Data de Pagamento:</Grid>
                            <Grid item xs={6}>{infoPo.dt_prevista}</Grid>
                            <Grid item xs={6}>Data da Autorização:</Grid>
                            <Grid item xs={6}>{infoPo.reg_date}</Grid>
                            <Grid item xs={6}>Autorizado por:</Grid>
                            <Grid item xs={6}>{infoPo.alterado_id}</Grid>
                            
                        </Grid>
                        ))

                        :''}

                       </div>

                  
                   
                </DialogContent>

                <DialogActions>
                
                <div className="bt w100 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                  Fechar
                </div>
               
                
              </DialogActions>
              
            </Dialog>


              <Dialog
                open={this.state.dataOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Data combinada de pagamento"}</DialogTitle>
                <DialogContent>

                    <MyDatePicker onChange={(e)=>this.changeData(e)} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data combinada" startDate={this.state.dataAberta}/>
                  
                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.saveData()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
              </Dialog>


              <Dialog
                open={this.state.abreDialogFone}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              
              >
                <DialogTitle>{"Falta o celular do fornecedor"}</DialogTitle>
                <DialogContent>

                <InputMask
                      mask="99999999999"
                      disabled={false}
                      maskChar=" "
                      onBlur={(e)=>this.setaCelular(e)}
                      >
                      {() =>   <TextField variant="outlined" label="Celular" fullWidth={true} placeholder="Celular" />}
                  </InputMask>

                  

                </DialogContent>
                <DialogActions>
                  <div className="bt w10 bgcolor_2" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  <div className="bt w10 bgcolor_1" onClick={()=>this.saveCelular()} color="secondary">
                    Definir
                  </div>
                </DialogActions>
              </Dialog>





              <Dialog
                open={this.state.verbaOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'lg'}
              >
                <DialogTitle>Solicitar verba</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <Grid item xs={2}>

                  <CurrencyTextField variant="outlined" label="Valor" fullWidth={true}  placeholder="Valor" onBlur={(e)=>this.upVerba(e,'valor')} currencySymbol="R$" outputformat="number"/>
                
                  </Grid>

                  <Grid item xs={2}>

                    <MyDatePicker onChange={(e)=>this.upVerba(e,'data')} view={["year", "month","date"]} format="dd/MM/yyyy"  label="Data combinada" startDate={this.state.dataAberta}/>
                  
                  </Grid>

                  <Grid item xs={2}>

                    <MySelect change={(e)=>this.upVerba(e,'plano')} label="Tipo de verba" list={this.state.listaVerbas}/>
                  
                  </Grid>

                  <Grid item xs={3}>
                    
                  <AutoComp empresa_id={this.state.empresa_id} sheet="users" tipo="pjs" label="CNPJ, Nome ou E-mail" blur={()=>this.void()} change={(e)=>this.upVerba(e,'pj')}/>
               
                  </Grid>

                  <Grid item xs={1}><IconButton size="large" onClick={()=>this.novoCNPJVerba()}><Add fontSize="large"/></IconButton></Grid>

                  <Grid item xs={2}>
                    <div className="bt large bgcolor_1" onClick={()=>this.solicitarVerba(0)} color="secondary">
                      Solicitar
                    </div>
                  
                  </Grid>

                      {/* 
                  <Grid item xs={1}>
                    <div className="bt large bgcolor_1" onClick={()=>this.dadosPF()} color="secondary">
                      PF
                    </div>
                      </Grid>
                    */}
                  
                 
                    {this.state.mostraDadosPF ? 
                  <Grid container spacing={1}>
                  <Grid item xs={2}>
                  <InputMask
                      mask="999.999.999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue='' 
                      onBlur={(e)=>this.upVerba(e,'cpf')}
                      >
                      {() =>   <TextField variant="outlined" label="CPF" fullWidth={true}  placeholder={"CPF"} 
                      InputProps={{
                        
                        style: { 
                          height:0,
                          padding: '25px 0px',color:'white',placeholder:'grey'},  
                  
                          
                      }}
                      />}
                  </InputMask>
                  </Grid>
                  <Grid item xs={2}>
                  <MySelect change={(e)=>this.upVerba(e,'tipo_chave')} label="Tipo de chave" list={this.state.chaves}/>
                  </Grid>
                  <Grid item xs={2}>
                 

                  <TextField variant="outlined" label="Chave PIX" onBlur={(e)=>this.upVerba(e,'pix')} fullWidth={true}  placeholder={"Chave PIX"} 
                      InputProps={{
                        
                        style: { 
                          height:0,
                          padding: '25px 0px',color:'white',placeholder:'grey'},  
                  
                          
                      }}
                      />


                  </Grid>
                  <Grid item xs={3}>
                    <div className="bt large bgcolor_1" onClick={()=>this.solicitarVerba(1)} color="secondary">
                      Solicitar Verba PF
                    </div>
                    </Grid>
                    

                    </Grid>
                    :''}
                </Grid>

                {this.state.mostraBuscaCNPJ ? 
                  
                  <div className="mt20">
                    <Grid container spacing={1}>
                      <Grid item xs={11}>
                      <TextField fullWidth={true} label="Digite o CNPJ para buscar" onBlur={(e)=>this.setaCNPJBusca(e)}/>
                      </Grid>
                
                    <Grid item xs={1} className="tr">
                    
                      <IconButton size="large" onClick={()=>this.buscarCNPJReceita()}><Search fontSize="large"/></IconButton>
                    </Grid>

                    </Grid>
                  </div>

                  :''}

                {this.state.mostraCNPJ && !this.state.mostraBuscaCNPJ ? 
                <div className="mt20">

                  {this.state.fornecedor.map((fornecedor,objectId) => (

                    
                <Grid key={objectId} container spacing={1}>
                  {fornecedor.id>0 ? '' : <div className="wp100 pd10 mt20 mb20 mini-titulo">Cadastrar Novo fornecedor</div>}
                <Grid item xs={4}>
                  <TextField autoFocus={true} color="secondary" autoComplete="off" variant="outlined" label="Nome" fullWidth={true}  defaultValue={fornecedor.nome} placeholder={"Nome"} onBlur={(e)=>this.dadosFornecedor(e,'nome')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Razão Social" fullWidth={true}  defaultValue={fornecedor.razao_social} placeholder="Razão Social" onBlur={(e)=>this.dadosFornecedor(e,'razao_social')}/>
                </Grid>

                <Grid item xs={4}>
                
                <InputMask
                      mask="99.999.999/9999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cnpj} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cnpj')}
                      >
                      {() =>   <TextField variant="outlined" label="CNPJ" kind="cnpj" fullWidth={true}  placeholder={"CNPJ"} />}
                  </InputMask>
                </Grid>
                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="E-mail" fullWidth={true}  defaultValue={fornecedor.email} placeholder={"E-Mail"} onBlur={(e)=>this.dadosFornecedor(e,'email')}/>
                </Grid>

                <Grid item xs={4}>
                <InputMask
                      mask="999.999.999-99"
                      disabled={false}
                      maskChar=" "
                      defaultValue={fornecedor.cpf} 
                      onBlur={(e)=>this.dadosFornecedor(e,'cpf')}
                      >
                      {() =>   <TextField variant="outlined" label="CPF" fullWidth={true}  placeholder={"CPF"} />}
                  </InputMask>
                </Grid>
                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Celular" fullWidth={true}  defaultValue={fornecedor.fone} placeholder="Celular" InputProps={this.state.fornecedorNovo === 2 ? {readOnly: true} : {}} onBlur={(e)=>this.dadosFornecedor(e,'fone')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Banco" fullWidth={true}  defaultValue={fornecedor.banco} placeholder="Banco" onBlur={(e)=>this.dadosFornecedor(e,'banco')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Agência" fullWidth={true}  defaultValue={fornecedor.agencia} placeholder="Agência" onBlur={(e)=>this.dadosFornecedor(e,'agencia')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Conta Corrente" fullWidth={true}  defaultValue={fornecedor.conta_corrente} placeholder="Conta Corrente" onBlur={(e)=>this.dadosFornecedor(e,'conta_corrente')}/>
                </Grid>

               
                
             
                

                <Grid item xs={4}>
                  <TextField variant="outlined" label="Endereço" fullWidth={true}  defaultValue={fornecedor.endereco} placeholder="Endereço" onBlur={(e)=>this.dadosFornecedor(e,'endereco')}/>
                </Grid>

                
                <Grid item xs={4}>
                  <TextField variant="outlined" label="Cidade" fullWidth={true}  defaultValue={fornecedor.cidade} placeholder="Cidade" onBlur={(e)=>this.dadosFornecedor(e,'cidade')}/>
                </Grid>

                <Grid item xs={4}>
                  <TextField variant="outlined" label="DRT" fullWidth={true}  defaultValue={fornecedor.drt} placeholder="DRT" InputProps={this.state.fornecedorNovo === 2 ? {readOnly: true} : {}} onBlur={(e)=>this.dadosFornecedor(e,'drt')}/>
                </Grid>

                
                

                


                </Grid>
                ))}
                </div>
                : ''}
                
                    {this.state.fornecedorNovo === 1 && !this.state.mostraBuscaCNPJ ? 
                <Grid container>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_2 mt20" onClick={()=>this.fechaDialog()} color="primary" autoFocus>
                    Cancelar
                  </div>
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={2}>
                  <div className="bt bgcolor_1 mt20 mb20" onClick={()=>this.cadastraFornecedor()} color="secondary">
                    Cadastrar
                  </div>
                  </Grid>
                </Grid>

                :''}

                <div className="mt30"></div>
                {this.state.listVerbas ? this.state.listVerbas.map((verba,objectId)=>(
                  
                  <div className="line-budget mb5 wp100">
                      <Grid key={objectId} container spacing={1}>

                            <Grid item xs={3} className="bgcolor_8 tc"><div className="ff-ro">{verba.plano}</div></Grid>
                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro">{<CurrencyFormat value={verba.valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                            <Grid item xs={2} className="bgcolor_8 tc"><div className="ff-ro">{verba.nome}</div></Grid>
                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro" >{this.formatDate2(verba.dt)}</div></Grid>
                            <Grid item xs={2} className="bgcolor_8 tc"><div className="ff-ro">{verba.status}</div></Grid>
                            {verba.link? 
                            <Grid item xs={1} className='bgcolor_7 tc'>{verba.link ? <IconButton color={verba.extra/1===0 ? "inherit" : "secondary"} target="blank" href={"/verbas/"+verba.link}><Payment/></IconButton> : <IconButton target="blank" href={"/prestacao/"+verba.id+'_'+verba.projeto_id}><Dvr/></IconButton> } {this.state.permission/1<2 ? <Tooltip title={verba.status==='Em aberto' ? "Travar verba" : "Liberar"} placement="top" arrow TransitionComponent={Zoom}><IconButton target="blank" color="secundary">{verba.status==='Em aberto' ? <LockOpen onClick={()=>this.travarVerba(verba.id)}/> : <Lock onClick={()=>this.liberarVerba(verba.id)}/>}</IconButton></Tooltip>: ''}</Grid>
                          :
                            <Grid item xs={1} className="bgcolor_7 tc">{verba.access/1===1 ? <IconButton target="blank"><HourglassTop/></IconButton> : <IconButton target="blank" href={"/prestacao/"+verba.id+'_'+verba.projeto_id}><Dvr/></IconButton> } {this.state.verbaPermission/1===4 ? <Tooltip title="Liberar verba" placement="top" arrow TransitionComponent={Zoom}><IconButton target="blank" onClick={()=>this.liberarVerba(verba.id)} color="secundary"><Lock/></IconButton></Tooltip> : ''}</Grid>
                          }
                            </Grid>
                  </div>

                )):''}
                <div className="mt20"></div>
                </div>                    
                </DialogContent>
                
              </Dialog>



              <Dialog
                open={this.state.acessoOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Acessos</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <Grid item xs={7}>

                    <TextField variant="outlined" label="E-mail" fullWidth={true}  placeholder="E-mail" onBlur={(e)=>this.upEmailAcesso(e)}/>
                
                  </Grid>

                  {this.state.eventos/1===0 ? 
                    <Grid item xs={3}>
                    <Switch checked={this.state.tipoAcesso} onChange={(e)=>this.changeAcesso(e)}  name=""/>Pós-produção
                    </Grid>
                  :
                    ''
                  }

                  <Grid item xs={2}>
                  <div className="bt large bgcolor_1" onClick={()=>this.darAcesso()} color="secondary">
                    Dar acesso
                  </div>
                  
                  </Grid>


                  <div className="mt20 wp100 mb30">

                    {this.state.listAcessos ? this.state.listAcessos.map((acesso,objectId)=>(
                  <Grid key={objectId} container spacing={0}>
                    <Grid item xs={1}><IconButton onClick={()=>this.deletaAcesso(acesso.id)} fontSize="small"><Delete/></IconButton></Grid>
                    <Grid item xs={11}><div className="pd15">{acesso.email}</div></Grid>
                    
                  </Grid>

                    )) : ''}
                    
                  </div>
                      
                  <Grid item xs={10}><TextField fullWidth={true} variant="outlined" label="Link do produtor" defaultValue={'https://landscape.to/viewsheet/'+this.state.id}/></Grid>
                    <Grid item xs={2} className="mt20">
                    <CopyToClipboard text={'https://landscape.to/viewsheet/'+this.state.id} onCopy={'https://landscape.to/viewsheet/'+this.state.id}>
                      <IconButton onClick={()=>this.copiarLink()} fontSize="small"><FileCopy/></IconButton>
                    </CopyToClipboard>
                  
                  </Grid>

                    {this.state.eventos/1===1 ? 
                  <Grid item xs={10}><TextField fullWidth={true} variant="outlined" label="Link do cliente" defaultValue={'https://landscape.to/viewclient/'+this.state.id}/></Grid>
                  :''
                }
                 {this.state.eventos/1===1 ? 
                    <Grid item xs={2} className="mt20">
                    <CopyToClipboard text={'https://landscape.to/viewclient/'+this.state.id} onCopy={'https://landscape.to/viewclient/'+this.state.id}>
                      <IconButton onClick={()=>this.copiarLink()} fontSize="small"><FileCopy/></IconButton>
                    </CopyToClipboard>
                  
                  </Grid>
                   :''
                  }

                </Grid>

                

                </div>                    
                </DialogContent>
                
              </Dialog>


              <Dialog
                open={this.state.openDate}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'sm'}
              >
                <DialogTitle>Data de fechamento</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <Grid item xs={8}>

                  <MyDatePicker onChange={(e)=>this.changeDataFechamento(e)} view={["year", "month","date"]} format="dd/MM/yyyy" label="Data combinada"/>
                
                  </Grid>


                  <Grid item xs={4}>
                  <div className="bt large bgcolor_1" onClick={()=>this.tolock(this.state.lockId)} color="secondary">
                    Continuar
                  </div>
                  
                  </Grid>


                 
                </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>



              <Dialog
                open={this.state.testeOpen}
                onClose={()=>this.fechaDialog()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
              >
                <DialogTitle>Cache teste de elenco</DialogTitle>
                <DialogContent>
                  <div className="wp100">
                <Grid container spacing={1}>
                  
                  <div className="wp100 mb20">
                  <div className="mt30"></div>
                    {this.state.listTestes ? this.state.listTestes.map((cacheteste,objectId)=>(
                  
                  <div key={objectId} className="line-budget mb5 wp100">
                      <Grid container spacing={1}>

                            <Grid item xs={4} className="bgcolor_8"><div className="ff-ro">{objectId+1} <span className="mr10"></span> {cacheteste.nome}</div></Grid>
                            <Grid item xs={2} className="bgcolor_7 tc"><div className="ff-ro">{<CurrencyFormat value={cacheteste.valor}  displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$'} />}</div></Grid>
                            <Grid item xs={2} className="bgcolor_8 tc"><div className="ff-ro">{cacheteste.status}</div></Grid>
                            
                            <Grid item xs={2} className="bgcolor_7 tc"><Tooltip title="Ver imagem DRT" placement="top" arrow TransitionComponent={Zoom}><IconButton target="blank" onClick={()=>this.verImagem(cacheteste.img)}><InsertDriveFile/></IconButton></Tooltip> {this.state.permission<=2 && cacheteste.status==='Em aberto' ? <Tooltip title="Deletar cache" placement="top" arrow TransitionComponent={Zoom}><IconButton onClick={()=>this.deletaCacheTeste(cacheteste.id)} target="blank"><Delete/></IconButton></Tooltip> : ''}</Grid>
                            <Grid item xs={2} className="bgcolor_7 tc">
                              {cacheteste.status==="Em aberto" ? 
                                <div onClick={()=>this.pagarTestePix(objectId)} className="bt bgcolor_1">Enviar Pix</div>
                                :
                                <div className="bt bgcolor_2">Pago</div>
                              }
                              </Grid>
                      </Grid>
                  </div>

                )):''}
               
                    </div>
                  
                      
                  <Grid item xs={10}><TextField fullWidth={true} variant="outlined" label="Link da planilha" defaultValue={'https://landscape.to/cacheteste/'+this.state.id}/></Grid>
                    <Grid item xs={2} className="mt20">
                    <CopyToClipboard text={'https://landscape.to/cacheteste/'+this.state.id} onCopy={'https://landscape.to/cacheteste/'+this.state.id}>
                      <IconButton onClick={()=>this.copiarLink()} fontSize="small"><FileCopy/></IconButton>
                    </CopyToClipboard>
                  
                  </Grid>
                </Grid>

                </div>                    
                </DialogContent>
                
              </Dialog>

          

        </div>


      

{this.state.eventos/1===1 ? 
        <div className="barra-valor z-100">
        <div className="barra-gradiente"></div>
              <Grid container spacing={1} className="bgcolor_8 pd30 br5">
              <Grid item xs={1}>
                <div>
                  <div className="color_9 mt20">Equipe Interna</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.o_valor_int)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">NF</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.o_valor_nf)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">ND</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.o_valor_nd)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">Fee</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.o_fee)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">Impostos</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.o_impostos)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <div>Orçado Total</div>
                  <div className="mt10 sub-titulo color_6">{<CurrencyFormat value={Math.floor(this.state.o_total)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>


              <Grid item xs={1}>
                <div>
                  <div className="color_9 mt20">Equipe Interna</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.r_valor_int)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">NF</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.r_valor_nf)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">ND</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.r_valor_nd)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">Fee</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.r_fee)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <div className="color_9 mt20">Impostos</div>
                  <div className="mt5 color_9 b">{<CurrencyFormat value={Math.floor(this.state.r_impostos)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <div>Realizado Total</div>
                  <div className="mt10 sub-titulo color_2">{<CurrencyFormat value={Math.floor(this.state.r_total)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'R$ '} />}</div>
                </div>
              </Grid>
               
              </Grid>
        
        </div>
   
    :''
  }


    </ThemeProvider>
  )


}
}
  